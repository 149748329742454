import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Import all necessary components
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import CourseAuthor from '../components/CourseAuthor';
import JourneySection from '../components/JourneySection';
import CourseSection from '../components/CourseSection';
import ForWho from '../components/ForWho';
import ReviewsSection from '../components/ReviewsSection';
import FreeGuideSection from '../components/FreeGuideSection';
import FAQSection from '../components/FAQSection';
import Footer from '../components/Footer';
import ExitIntentModal from '../components/ExitIntentModal'; // Модальное окно

// --- Animation Hook ---
const useFadeIn = (threshold = 0.1) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold }
    );
    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [threshold]);
  return ref;
};

// --- Animated Section Wrapper ---
const AnimatedSectionWrapper: React.FC<{ children: React.ReactNode, threshold?: number }> = ({ children, threshold }) => {
  const ref = useFadeIn(threshold);
  return <div ref={ref} className="fade-in-section">{children}</div>;
};

// --- HomePage Component ---
const HomePage: React.FC = () => {
  // --- Состояние для Модального Окна Гайда ---
  const [showGuideModal, setShowGuideModal] = useState(false);
  // Флаги для отслеживания (используем useRef, чтобы не вызывать перерендер)
  const modalShown = useRef(false);      // Показано ли модальное окно уже (любым способом)
  const guideRequested = useRef(false); // Запрошен ли гайд (через форму или модалку)

  // Функция, вызываемая при успешном запросе гайда
  const handleGuideSuccess = () => {
    guideRequested.current = true;
    modalShown.current = true; // Если успешно запросили, считаем, что и показали
    try {
      // Сохраняем оба флага в sessionStorage
      sessionStorage.setItem('amazonFreedomGuideRequested', 'true');
      sessionStorage.setItem('amazonFreedomModalShown', 'true');
    } catch (e) {
      console.warn("Could not write to sessionStorage");
    }
    console.log("Guide requested, modal disabled for this session.");
  };

  // --- Логика показа модального окна (Комбинированная) ---
  useEffect(() => {
    // 1. Проверяем sessionStorage при загрузке
    try {
      if (sessionStorage.getItem('amazonFreedomGuideRequested') === 'true') {
        guideRequested.current = true;
        modalShown.current = true; // Если гайд запрошен, окно не показываем
        console.log("Guide already requested, modal disabled.");
        return; // Выходим, ничего делать не нужно
      }
      if (sessionStorage.getItem('amazonFreedomModalShown') === 'true') {
        modalShown.current = true; // Если окно уже показывали, тоже не показываем
        console.log("Modal already shown, modal disabled.");
        return; // Выходим
      }
    } catch (e) {
      console.warn("Could not read from sessionStorage");
    }

    // Переменные для таймеров и слушателей
    let timeBasedTimeoutId: NodeJS.Timeout | null = null;
    let visibilityTimeoutId: NodeJS.Timeout | null = null;
    let exitIntentListenerAdded = false;
    let visibilityListenerAdded = false;
    let timeListenerActive = true; // Флаг для таймера

    // Функция показа модалки (если условия позволяют)
    const triggerModal = (reason: string) => {
      if (!modalShown.current && !guideRequested.current) {
        console.log(`Triggering Modal via: ${reason}`);
        setShowGuideModal(true);
        modalShown.current = true; // Показываем только один раз
        // Сохраняем в sessionStorage, что показали
        try {
          sessionStorage.setItem('amazonFreedomModalShown', 'true');
        } catch (e) { console.warn("Could not write to sessionStorage"); }
        // Отключаем все остальные триггеры
        cleanupListeners();
      } else {
        console.log(`Modal trigger skipped (${reason}): Modal shown: ${modalShown.current}, Guide requested: ${guideRequested.current}`);
      }
    };

    // 2. Триггер по времени (после N секунд)
    console.log("Setting time-based trigger");
    timeBasedTimeoutId = setTimeout(() => {
      if (timeListenerActive) { // Проверяем, не отключен ли уже таймер
        triggerModal("Time Elapsed");
        timeListenerActive = false; // Деактивируем таймер после срабатывания
      }
    }, 20000); // Показать через 20 секунд

    // 3. Триггер по уходу мыши вверх (десктоп)
    const handleMouseLeave = (event: MouseEvent) => {
      if (event.clientY <= 5) {
        triggerModal("Mouse Leave Top");
      }
    };

    // 4. Триггер по смене видимости (десктоп/мобильные)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Запускаем с небольшой задержкой
        visibilityTimeoutId = setTimeout(() => triggerModal("Visibility Hidden"), 1000);
      } else {
        // Если вернулся, отменяем показ по этому триггеру
        if (visibilityTimeoutId) clearTimeout(visibilityTimeoutId);
      }
    };

    // Функция для добавления слушателей exit-intent (после задержки)
    const addIntentListeners = () => {
      if (!exitIntentListenerAdded && !modalShown.current && !guideRequested.current) {
        console.log("Adding mouseleave listener");
        document.addEventListener('mouseleave', handleMouseLeave);
        exitIntentListenerAdded = true;
      }
      if (!visibilityListenerAdded && !modalShown.current && !guideRequested.current) {
        console.log("Adding visibilitychange listener");
        document.addEventListener('visibilitychange', handleVisibilityChange);
        visibilityListenerAdded = true;
      }
    };

    // Добавляем слушатели намерения уйти через 5 секунд
    const intentListenerTimeout = setTimeout(addIntentListeners, 5000);

    // Функция очистки всех слушателей и таймеров
    const cleanupListeners = () => {
      console.log("Cleaning up all modal triggers");
      if (timeBasedTimeoutId) clearTimeout(timeBasedTimeoutId);
      if (visibilityTimeoutId) clearTimeout(visibilityTimeoutId);
      if (intentListenerTimeout) clearTimeout(intentListenerTimeout);
      if (exitIntentListenerAdded) document.removeEventListener('mouseleave', handleMouseLeave);
      if (visibilityListenerAdded) document.removeEventListener('visibilitychange', handleVisibilityChange);
      timeListenerActive = false; // Деактивируем таймер
    };

    // Очистка при размонтировании компонента
    return cleanupListeners;

  }, []); // Пустой массив зависимостей, эффект выполняется один раз при монтировании

  return (
    <PageContainer>
      <Header />
      <main>
        <HeroSection />
        <AnimatedSectionWrapper threshold={0.15}>
          <CourseAuthor />
        </AnimatedSectionWrapper>
        <AnimatedSectionWrapper threshold={0.15}>
          <JourneySection />
        </AnimatedSectionWrapper>
        <AnimatedSectionWrapper threshold={0.1}>
          <CourseSection />
        </AnimatedSectionWrapper>
        <AnimatedSectionWrapper threshold={0.15}>
          <ForWho />
        </AnimatedSectionWrapper>
        <AnimatedSectionWrapper threshold={0.1}>
          <ReviewsSection />
        </AnimatedSectionWrapper>

        {/* --- КНОПКА CTA ПОСЛЕ ОТЗЫВОВ --- */}
        <CtaAfterReviewsWrapper>
          <CtaButtonAfterReviews href="#courses">
            Готов(а) начать? Выбрать свой курс!
          </CtaButtonAfterReviews>
        </CtaAfterReviewsWrapper>
        {/* --- КОНЕЦ КНОПКИ --- */}

        {/* Передаем колбэк в FreeGuideSection */}
        <AnimatedSectionWrapper threshold={0.2}>
          <div><FreeGuideSection onSuccess={handleGuideSuccess} /></div>
        </AnimatedSectionWrapper>
        <AnimatedSectionWrapper threshold={0.1}>
          <FAQSection />
        </AnimatedSectionWrapper>
      </main>
      <Footer />

      {/* --- Рендеринг Модального Окна --- */}
      <ExitIntentModal
        isOpen={showGuideModal}
        onClose={() => setShowGuideModal(false)}
        onSuccess={handleGuideSuccess} // Передаем колбэк
      />
    </PageContainer>
  );
};

// --- Styles (без изменений) ---
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;

  main {
    flex-grow: 1;
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
                transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: opacity, transform;
  }

  .fade-in-section.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CtaAfterReviewsWrapper = styled.div`
    text-align: center;
    padding: 60px 20px 80px 20px;
    background-color: #f8f9fa; // Match reviews background

    @media (max-width: 991px) {
        padding: 40px 15px 60px 15px;
    }
`;

const CtaButtonAfterReviews = styled.a`
    display: inline-block;
    padding: 18px 55px;
    font-family: 'Jost', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #ffffff;
    background: linear-gradient(90deg, #ff9900 0%, #ff5e00 100%);
    border: none;
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(255, 100, 0, 0.35);
    text-align: center;

    &:hover {
        transform: translateY(-4px) scale(1.03);
        box-shadow: 0 12px 25px rgba(255, 100, 0, 0.45);
        background: linear-gradient(90deg, #ffae40 0%, #ff7d3b 100%);
    }

    &:active {
        transform: translateY(0px) scale(0.97);
        box-shadow: 0 5px 15px rgba(255, 100, 0, 0.25);
    }

     @media (max-width: 767px) {
         font-size: 17px;
         padding: 16px 40px;
     }
`;

export default HomePage;