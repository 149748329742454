import React, { useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';

// --- Data ---
// My Journey Data
const stepsData = [
  { iconSymbol: '$', year: '2018-2020', description: 'Аккаунт менеджер Амазон\nСредняя зп 1700$' },
  { iconSymbol: '$$', year: '2020-2022', description: 'Бизнес по системе онлайн арбитраж\nСредняя выручка 60.000$' },
  { iconSymbol: '$$$', year: '2022 – по н. вр.', description: 'Запускаю товары по системе Private Label\nОбщая выручка > 1 миллиона $' },
];

// Advantages Data
const advantagesData = [
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/75e700109e9a9d8a49202801d5c606a194a2f8b0d6255aa7c58f01dad1dea6c2?apiKey=35bf81d825094f66b687d157fe963e7b&",
    altText: "Огромные перспективы Amazon",
    title: "Огромные перспективы",
    description: "Амазон – третья поисковая система в мире после Google и Youtube. Прибыль компании ежегодно растет.",
  },
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/5a743c1ebf1890c05aba9f32fc83544f2a05dc749dd5ac1c75b33a8b43726963?apiKey=35bf81d825094f66b687d157fe963e7b&",
    altText: "Жизнь мечты с бизнесом на Amazon",
    title: "Жизнь мечты",
    description: "Бизнес на Амазоне позволит вам не зависеть от начальника и жить в любой точке мира. Вы будете зарабатывать деньги в долларах и не переживать за свое будущее.",
  },
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/b84f7c03b34383c802584b892fbbd894aac696ad3fcbbfb8a75a4fa43fd2254e?apiKey=35bf81d825094f66b687d157fe963e7b&",
    altText: "Реальные кейсы и опыт автора",
    title: "Реальные кейсы",
    description: "Вы можете довериться мне, ведь я действующий селлер и на своем примере доказываю, что на этом можно много зарабатывать. Примеры моих учеников также это подтверждают.",
  },
];


// --- Animations ---
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const drawLineDesktop = keyframes`
  from { width: 0; }
  to { width: calc(100% - 160px); }
`;

const drawLineMobile = keyframes`
  from { height: 0; }
  to { height: calc(100% - 80px); }
`;

const pulseGlow = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255, 153, 0, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(255, 153, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 153, 0, 0); }
`;

// --- Styled Components ---

const StepCircle = styled.div`
  width: 50px;
  height: 50px;
  background-color: #FF9900;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  box-shadow: 0 0 10px rgba(0,0,0, 0.1);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  animation: ${pulseGlow} 2s infinite;
  animation-play-state: paused;

  @media (max-width: 767px) {
    width: 44px;
    height: 44px;
    font-size: 16px;
    top: 0; left: 13px; transform: translateX(-50%) scale(0);
  }
`;

const StepContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px; // Space for the circle above
    @media (max-width: 767px) {
        align-items: flex-start;
        padding-top: 15px;
    }
`;

const StepYear = styled.div`
    font: 700 24px/1.2 'Jost', sans-serif;
    margin-bottom: 10px;
    color: #0E0B2C;
    @media (max-width: 767px) { font-size: 20px; }
`;

const StepDescription = styled.div`
    font: 400 16px/1.6 'Jost', sans-serif;
    color: #444;
    white-space: pre-line;
    @media (max-width: 767px) { font-size: 15px; }
`;

const Step = styled.div`
  display: flex; flex-direction: column; align-items: center; flex: 1; max-width: 280px;
  position: relative; padding-top: 65px; text-align: center; z-index: 1;
  opacity: 0;

  @media (max-width: 767px) {
    max-width: 100%; padding-top: 0; padding-left: 60px; align-items: flex-start; text-align: left; width: 100%;
  }
`;

const StepsContainer = styled.div`
  display: flex; justify-content: space-around; width: 100%; max-width: 1050px;
  align-items: flex-start; position: relative; margin-bottom: 120px;

  &::before {
    content: ''; position: absolute; background-color: #e0e0e0; top: 50px; left: 80px;
    width: 0; height: 4px; transform: translateY(-50%); z-index: 0; border-radius: 2px;
  }

  @media (max-width: 767px) {
    flex-direction: column; align-items: stretch; gap: 70px; max-width: 450px;
    margin-left: auto; margin-right: auto; margin-bottom: 80px;
    &::before {
      top: 40px; left: 13px; transform: translateX(-50%); width: 4px; height: 0; bottom: 40px;
    }
  }
`;

// Styling for the columns holding advantage cards
const AdvantageColumn = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0; // Initial state for animation

  // --- FIX: Use calc to ensure 3 columns fit with the gap ---
  width: calc((100% - 80px) / 3); // 80px = 2 * 40px gap
  max-width: 380px; // Keep max-width as a safety/design limit

  @media (max-width: 991px) { // Stack columns on tablets/mobiles
      width: 85%; // Override calc width for stacking
      max-width: 500px;
   }
  @media (max-width: 600px) { // Full width on smaller mobiles
     width: 100%; // Override calc width for stacking
     max-width: none; // Remove max-width constraint
   }
`;

// Main section container with animation trigger logic
const SectionContainer = styled.section`
  width: 100%; max-width: 1240px; margin: 100px auto; padding: 40px 20px;
  box-sizing: border-box; color: #2c2d2f; display: flex; flex-direction: column; align-items: center;

  &.animate {
    /* Animate the line */
    ${StepsContainer}::before {
      animation: ${drawLineDesktop} 1.2s 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
       @media (max-width: 767px) {
           animation: ${drawLineMobile} 1.2s 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
       }
    }

    /* Animate Steps */
    ${Step} {
        &:nth-child(1) { animation: ${fadeIn} 0.7s 0.8s ease-out forwards; }
        &:nth-child(2) { animation: ${fadeIn} 0.7s 1.0s ease-out forwards; }
        &:nth-child(3) { animation: ${fadeIn} 0.7s 1.2s ease-out forwards; }

        ${StepCircle} {
             transform: translate(-50%, -50%) scale(1);
             transform-origin: center center;
             animation-play-state: running;
             animation-delay: 1.5s;
        }
         @media (max-width: 767px) {
             ${StepCircle} {
                 transform: translateX(-50%) scale(1);
                 transform-origin: center center;
            }
        }
    }

    /* Animate Advantages with stagger */
    ${AdvantageColumn} {
        &:nth-child(1) { animation: ${fadeIn} 0.7s 1.4s ease-out forwards; }
        &:nth-child(2) { animation: ${fadeIn} 0.7s 1.6s ease-out forwards; }
        &:nth-child(3) { animation: ${fadeIn} 0.7s 1.8s ease-out forwards; }
    }
  }

  @media (max-width: 991px) { margin: 70px auto; padding: 30px 15px; }
`;


const InnerWrapper = styled.div` display: flex; width: 100%; flex-direction: column; align-items: center; `;

const Title = styled.h2`
  font: 700 64px/1.1 'Bebas Neue', sans-serif; text-align: center;
  margin: 0 0 80px 0; letter-spacing: 1.5px;
  @media (max-width: 991px) { font-size: 52px; margin-bottom: 60px; }
  @media (max-width: 767px) { font-size: 42px; }
`;

const WhyThisCourseContainer = styled.h2`
  color: #1a2a4a; font: 700 64px/1.1 'Bebas Neue', sans-serif;
  margin: 0 0 70px 0; text-align: center; letter-spacing: 1.5px;
  @media (max-width: 991px) { font-size: 52px; margin-bottom: 50px; }
  @media (max-width: 767px) { font-size: 42px; }
`;

const YellowText = styled.span` color: #FF9900; `;

// Container for the row/column of advantages
const AdvantagesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

// Flex container for advantage items
const AdvantagesContent = styled.div`
  gap: 40px; // Space between advantage cards
  display: flex;
  justify-content: center; // Center items if fewer than 3 fill the row
  // flex-wrap: wrap; // REMOVED wrap on desktop to force single line
  width: 100%;
  max-width: 1240px;

  @media (max-width: 991px) { // Stack into a column on tablets/mobiles
    flex-direction: column;
    align-items: center;
    gap: 35px;
    // On mobile, wrapping is implicitly handled by flex-direction: column
  }
`;

// Individual advantage card styling
const Advantage = styled.div`
  display: flex; flex-grow: 1; flex-direction: column; background-color: #fff; border-radius: 18px;
  padding: 35px; box-shadow: 0 8px 25px rgba(0, 30, 80, 0.08);
  border: 1px solid #e8efff; transition: transform 0.3s ease, box-shadow 0.3s ease; overflow: hidden;

  &:hover { transform: translateY(-10px); box-shadow: 0 15px 35px rgba(0, 30, 80, 0.14); }
  @media (max-width: 991px) { padding: 30px; min-height: auto; }
`;

const AdvantageImage = styled.img`
  aspect-ratio: 1.6;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
`;

const AdvantageTitle = styled.h3`
  color: #002a6e; font: 700 26px/1.3 'Jost', sans-serif;
  margin: 0 0 15px 0;
  @media (max-width: 767px) { font-size: 22px; }
`;

const AdvantageDescription = styled.p`
  color: #334155; font: 400 17px/1.8 'Jost', sans-serif;
  margin: 0; flex-grow: 1;
  @media (max-width: 991px) { font-size: 16px; }
`;

// --- Component Logic ---
const JourneySection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.15 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef}>
      <InnerWrapper>
        <Title>МОЙ ПУТЬ</Title>
        <StepsContainer>
          {stepsData.map((step, index) => (
            <Step key={index}>
              <StepCircle>{step.iconSymbol}</StepCircle>
              <StepContent>
                <StepYear>{step.year}</StepYear>
                <StepDescription>{step.description}</StepDescription>
              </StepContent>
            </Step>
          ))}
        </StepsContainer>
      </InnerWrapper>

      <WhyThisCourseContainer>
        Почему <YellowText>вам стоит</YellowText> пройти этот курс
      </WhyThisCourseContainer>

      <AdvantagesContainer>
        <AdvantagesContent>
          {advantagesData.map((advantage, index) => (
            <AdvantageColumn key={index}>
              <Advantage>
                <AdvantageImage loading="lazy" src={advantage.imageSrc} alt={advantage.altText} />
                <AdvantageTitle>{advantage.title}</AdvantageTitle>
                <AdvantageDescription>{advantage.description}</AdvantageDescription>
              </Advantage>
            </AdvantageColumn>
          ))}
        </AdvantagesContent>
      </AdvantagesContainer>
    </SectionContainer>
  );
};

export default JourneySection;