import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios'; // Если используете

// Placeholder image - REPLACE THIS
const GuideMockup = "https://via.placeholder.com/350x450.png?text=Free+Guide+Cover";

// --- Define shared styles used here ---
const HighlightText = styled.span`
  color: #ff9900;
  font-weight: 600;
`;
const CheckCircleIcon = () => <span style={{ color: '#28a745', marginRight: '8px', flexShrink: 0 }}>✔</span>;
// --- End definitions ---

// --- Animation ---
const tada = keyframes`
  /* ... (анимация тада без изменений) ... */
  from { transform: scale3d(1, 1, 1); }
  10%, 20% { transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% { transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% { transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to { transform: scale3d(1, 1, 1); }
`;

// --- ИЗМЕНЕНИЕ: Добавлен тип пропсов ---
interface FreeGuideSectionProps {
    onSuccess?: () => void; // Необязательный колбэк
}

// --- Component ---
const FreeGuideSection: React.FC<FreeGuideSectionProps> = ({ onSuccess }) => { // <-- Принимаем onSuccess из пропсов
    const [email, setEmail] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); setError(''); setIsLoading(true);
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setError('Пожалуйста, введите корректный email.'); setIsLoading(false); return;
        }
        try {
            console.log('Submitting email for free guide:', email);
            // === ВАШ ЗАПРОС НА СЕРВЕР ===
            // await axios.post('/api/subscribe-guide', { email }); // Пример
            await new Promise(resolve => setTimeout(resolve, 1000)); // Имитация
            // ===========================
            setIsSubmitted(true); setEmail('');
            // --- ИЗМЕНЕНИЕ: Вызываем onSuccess, если он передан ---
            if (onSuccess) {
                onSuccess();
            }
        } catch (err: any) {
            console.error("Error submitting guide email:", err);
            setError(err.response?.data?.message || 'Не удалось отправить запрос. Попробуйте позже.');
        } finally { setIsLoading(false); }
    };

    // ... (остальной JSX компонента остается без изменений) ...
    return (
        <SectionWrapperGuide>
            <ContainerGuide>
                <GuideImageWrapper>
                    <GiftBadge>ПОДАРОК!</GiftBadge>
                    <GuideImage src={GuideMockup} alt="Бесплатный гайд: Первые шаги на Amazon" loading="lazy" />
                </GuideImageWrapper>
                <ContentWrapperGuide>
                    <TextContentGuide>
                        <TitleGuide>НЕ ЗНАЕТЕ С ЧЕГО НАЧАТЬ? <HighlightText>ПОЛУЧИТЕ ПЛАН!</HighlightText></TitleGuide>
                        <DescriptionGuide>
                            Заберите наш <HighlightText>бесплатный PDF-гайд</HighlightText> "Amazon Quick Start". Это концентрат знаний для быстрого старта: как избежать типовых ошибок, найти первый товар и не слить бюджет.
                        </DescriptionGuide>
                        <BulletPoints>
                            <li><CheckCircleIcon /> Пошаговый план запуска за 7 дней.</li>
                            <li><CheckCircleIcon /> Критерии выбора <HighlightText>прибыльной ниши</HighlightText>.</li>
                            <li><CheckCircleIcon /> Список <HighlightText>ТОП-5 ошибок</HighlightText> новичков (и как их избежать).</li>
                        </BulletPoints>
                    </TextContentGuide>
                    <FormWrapper>
                        {isSubmitted ? (
                            <SuccessMessageGuide>
                                🔥 Отлично! Гайд отправлен.<br /> Ищите письмо от "Amazon Freedom" (проверьте Спам).
                            </SuccessMessageGuide>
                        ) : (
                            <FormGuide onSubmit={handleSubmit} noValidate>
                                <FormTitle>Получите гайд прямо сейчас:</FormTitle>
                                <InputGuide
                                    type="email" placeholder="Ваш основной email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} required aria-label="Ваш email" disabled={isLoading} />
                                {error && <ErrorMessageGuide>{error}</ErrorMessageGuide>}
                                <SubmitButtonGuide type="submit" disabled={isLoading}>
                                    {isLoading ? 'Отправляем...' : 'СКАЧАТЬ ГАЙД БЕСПЛАТНО'}
                                </SubmitButtonGuide>
                                <PrivacyNotice>
                                    100% бесплатно. Отписка в любое время. Ваши данные в безопасности.
                                </PrivacyNotice>
                            </FormGuide>
                        )}
                    </FormWrapper>
                </ContentWrapperGuide>
            </ContainerGuide>
        </SectionWrapperGuide>
    );
};

// --- Styled Components (без изменений, код ниже) ---
const SectionWrapperGuide = styled.section` padding: 90px 20px; background: #fff; color: #2c2d2f; @media (max-width: 991px) { padding: 70px 15px; } `;
const ContainerGuide = styled.div` max-width: 1050px; margin: 0 auto; background-color: #f8faff; border: 1px solid #dfeaff; border-radius: 20px; padding: 40px 50px; box-shadow: 0 15px 40px rgba(0, 78, 228, 0.08); display: flex; align-items: center; gap: 60px; @media (max-width: 991px) { flex-direction: column; padding: 35px 30px; gap: 35px; text-align: center; } @media (max-width: 767px) { padding: 30px 20px; } `;
const GuideImageWrapper = styled.div` flex: 0 0 320px; align-self: center; position: relative; @media (max-width: 991px) { flex-basis: 280px; margin-bottom: 10px; } @media (max-width: 767px) { flex-basis: 220px; } `;
const GiftBadge = styled.div` position: absolute; top: 15px; left: -10px; background: #dc3545; color: #fff; padding: 6px 12px; font-size: 14px; font-weight: 700; border-radius: 4px; transform: rotate(-5deg); z-index: 2; box-shadow: 2px 2px 6px rgba(0,0,0,0.2); `;
const GuideImage = styled.img` display: block; width: 100%; height: auto; object-fit: contain; filter: drop-shadow(0 12px 20px rgba(0,0,0, 0.18)); transition: transform 0.3s ease; &:hover { transform: scale(1.03); } `;
const ContentWrapperGuide = styled.div` display: flex; flex-direction: column; gap: 25px; flex: 1; `;
const TextContentGuide = styled.div``;
const TitleGuide = styled.h2` font: 700 44px/1.2 'Bebas Neue', sans-serif; margin: 0 0 18px 0; color: #0E0B2C; letter-spacing: 0.5px; @media (max-width: 991px) { font-size: 38px; } @media (max-width: 767px) { font-size: 32px; } `;
const DescriptionGuide = styled.p` font: 400 17px/1.7 'Jost', sans-serif; color: #444; margin: 0 0 25px 0; @media (max-width: 991px) { font-size: 16px; } `;
const BulletPoints = styled.ul` list-style: none; padding: 0; margin: 0 0 25px 0; display: flex; flex-direction: column; gap: 10px; font-size: 16px; color: #333; @media (max-width: 991px) { align-items: center; text-align: left; max-width: 350px; margin-left:auto; margin-right:auto;} li { display: flex; align-items: flex-start; line-height: 1.5; } `;
const FormWrapper = styled.div` background-color: #fff; border-radius: 12px; padding: 25px 30px; border: 1px solid #e0e8f5; box-shadow: 0 5px 15px rgba(0, 50, 100, 0.05); margin-top: 10px; `;
const FormTitle = styled.p` margin: 0 0 15px 0; font-size: 16px; font-weight: 600; text-align: center; color: #004ee4; `;
const FormGuide = styled.form` display: flex; flex-direction: column; gap: 15px; align-items: stretch; `;
const InputGuide = styled.input` padding: 14px 18px; border: 1px solid #ccc; border-radius: 8px; font-family: 'Jost', sans-serif; font-size: 16px; transition: border-color 0.2s ease, box-shadow 0.2s ease; background-color: #fff; &:focus { outline: none; border-color: #004ee4; box-shadow: 0 0 0 3px rgba(0, 78, 228, 0.1); } &::placeholder { color: #aaa; } &:disabled { background-color: #e9ecef; cursor: not-allowed; } `;
const SubmitButtonGuide = styled.button`
    padding: 15px 25px; background-color: #ff9900; color: #fff; border: none; border-radius: 8px; font-family: 'Jost', sans-serif; font-size: 17px; font-weight: 700; letter-spacing: 0.5px; cursor: pointer; transition: all 0.25s ease; box-shadow: 0 5px 12px rgba(255, 153, 0, 0.3);
    &:hover { background-color: #e08900; transform: translateY(-2px) scale(1.02); box-shadow: 0 7px 18px rgba(255, 153, 0, 0.4); }
    &:active { transform: translateY(0px) scale(0.98); }
    &:disabled { background-color: #b0b0b0; cursor: not-allowed; box-shadow: none; transform: none; }
`;
const ErrorMessageGuide = styled.p` color: #d93025; font-size: 14px; margin: -5px 0 0 0; text-align: center; `;
const SuccessMessageGuide = styled.div` color: #155724; font-size: 16px; font-weight: 500; background-color: #d4edda; border: 1px solid #c3e6cb; border-radius: 8px; padding: 20px; text-align: center; line-height: 1.5; `;
const PrivacyNotice = styled.p` font-size: 12px; color: #888; text-align: center; line-height: 1.4; margin-top: 0px; `;

export default FreeGuideSection;