import React from 'react';
import styled from 'styled-components';
import { Mail, Phone, Send, Link as LinkIcon } from 'react-feather'; // Example icons

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // Get current year

  return (
    <FooterContainer>
      <FooterContent>
        {/* Column 1: Contacts */}
        <FooterColumn>
          <FooterHeading>Свяжитесь с нами</FooterHeading>
          <FooterLink href="mailto:info@amazonfreedom.ru">
            <Mail size={16} /> info@amazonfreedom.ru
          </FooterLink>
          {/* <FooterLink href="tel:+1..."> <Phone size={16} /> +1 ... </FooterLink>  */}
        </FooterColumn>

        {/* Column 2: Quick Links (Optional) */}
        <FooterColumn>
          <FooterHeading>Навигация</FooterHeading>
          <FooterLink href="#courses">Курсы</FooterLink>
          <FooterLink href="#reviews">Отзывы</FooterLink>
          <FooterLink href="#faq">FAQ</FooterLink>
        </FooterColumn>

        {/* Column 3: Legal */}
        <FooterColumn>
          <FooterHeading>Правовая информация</FooterHeading>
          <FooterLink href="/terms" target="_blank" rel="noopener noreferrer"> {/* Assume terms page exists */}
            Пользовательское соглашение
          </FooterLink>
          <FooterLink href="/privacy" target="_blank" rel="noopener noreferrer"> {/* Assume privacy page exists */}
            Политика конфиденциальности
          </FooterLink>
        </FooterColumn>

        {/* Column 4: Social */}
        <FooterColumn>
          <FooterHeading>Мы в соцсетях</FooterHeading>
          <FooterLink href="https://t.me/juliaintheusachat" target="_blank" rel="noopener noreferrer">
            <Send size={16} /> Telegram Канал
          </FooterLink>
          {/* Add other social links here */}
        </FooterColumn>

      </FooterContent>
      <FooterBottom>
        <FooterBottomText>
          © {currentYear} Amazon Freedom | Все права защищены.
        </FooterBottomText>
        <FooterBottomText>
          ИП Кузьмич Юлия Юрьевна | ИНН: 860237504280
        </FooterBottomText>
      </FooterBottom>
    </FooterContainer>
  );
};

// --- Styled Components ---
const FooterContainer = styled.footer` /* Changed div to footer */
  background: linear-gradient(160deg, #08101f -0.66%, #182c51 176.61%); /* Darker gradient */
  padding: 60px 20px 30px 20px; /* More top padding, less bottom */
  color: #adb5bd; /* Light gray text */

   @media (max-width: 991px) { padding: 40px 15px 20px 15px; }
`;

const FooterContent = styled.div`
  display: grid; /* Use grid for columns */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive columns */
  gap: 30px 40px; /* Row and column gap */
  width: 100%;
  max-width: 1240px; /* Consistent max-width */
  margin: 0 auto 40px auto; /* Center content and add bottom margin */

  @media (max-width: 767px) {
     grid-template-columns: 1fr 1fr; /* 2 columns on smaller screens */
     gap: 30px;
     text-align: center; /* Center text in columns */
  }
   @media (max-width: 480px) {
     grid-template-columns: 1fr; /* Stack on very small screens */
   }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px; /* Space between items in column */
   @media (max-width: 767px) {
     align-items: center; /* Center items when text is centered */
   }
`;

const FooterHeading = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #e9ecef; /* Brighter heading color */
  margin: 0 0 5px 0;
  font-family: 'Jost', sans-serif;
`;

const FooterLink = styled.a`
  color: #adb5bd;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Jost', sans-serif;
  display: inline-flex; /* Align icon and text */
  align-items: center;
  gap: 8px;
  transition: color 0.2s ease;
  width: fit-content; /* Prevent link taking full width */

   @media (max-width: 767px) {
     justify-content: center; /* Center icon+text when column is centered */
   }


  svg { /* Style icons */
    width: 16px; height: 16px;
    stroke-width: 2;
     color: #6c757d; /* Slightly darker icon color */
     transition: color 0.2s ease;
  }

  &:hover {
    color: #f8f9fa; /* White on hover */
    text-decoration: underline;
     svg {
        color: #adb5bd;
     }
  }
`;


const FooterBottom = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  width: 100%;
  max-width: 1240px; /* Match content width */
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* Subtle separator */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
  gap: 15px;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    gap: 10px;
     margin-top: 20px;
     padding-top: 20px;
  }
`;

const FooterBottomText = styled.p`
  font-size: 13px;
  color: #8a94a0; /* Slightly darker gray */
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  font-family: 'Jost', sans-serif;
`;


export default Footer;