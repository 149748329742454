// GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* --- Ваши @font-face правила остаются без изменений --- */
  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // Добавлено для лучшей производительности
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // Добавлено
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Book.ttf') format('truetype');
    font-weight: 300; // Book часто соответствует 300 или 400
    font-style: normal;
    font-display: swap; // Добавлено
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Light.ttf') format('truetype');
    font-weight: 200; // Light часто соответствует 200 или 300
    font-style: normal;
    font-display: swap; // Добавлено
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('/fonts/BebasNeue-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap; // Добавлено
  }

  /* --- Добавьте стили для html здесь --- */
  html {
    scroll-behavior: smooth;
  }

  /* --- Ваши стили для body остаются --- */
  body {
    font-family: 'Jost', sans-serif; /* ИЗМЕНЕНО: Bebas Neue не подходит для основного текста */
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased; /* Для лучшего рендеринга шрифтов */
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5; /* Базовая высота строки */
    color: #333; /* Базовый цвет текста */
  }

  /* --- Дополнительные общие сбросы/стили (опционально) --- */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    /* Пример использования Bebas Neue для заголовков */
    font-family: 'Bebas Neue', sans-serif;
    margin-top: 0; /* Убрать верхний отступ по умолчанию */
  }
`;

export default GlobalStyle;