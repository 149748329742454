import React from 'react';
import styled, { keyframes, css } from 'styled-components';
// Ensure paths are correct
import Julia from '../assets/julia.png';
import AmazonStats from '../assets/amazonstats.png';

// --- Define keyframes ---
const slideInLeft = keyframes`
  from { opacity: 0; transform: translateX(-30px); }
  to { opacity: 1; transform: translateX(0); }
`;
const slideInRight = keyframes`
  from { opacity: 0; transform: translateX(30px); }
  to { opacity: 1; transform: translateX(0); }
`;
// Define fadeInUp used in ExperienceItem
const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;
// Keyframes for images
const scaleIn = keyframes` from { transform: translateX(-50%) scale(1.1); opacity: 0;} to { transform: translateX(-50%) scale(1); opacity: 1;} `;
const popIn = keyframes` from { transform: rotate(4deg) scale(0.9); opacity: 0;} to { transform: rotate(4deg) scale(1); opacity: 1;} `;
// --- End keyframes ---


const CourseAuthor: React.FC = () => {
    // Store icon URLs for cleaner mapping/passing props
    const iconUrls = [
        "https://cdn.builder.io/api/v1/image/assets/TEMP/1db66fd3c8e8ba2e12d29975c7d8da7205a757a12e15eef8282814b33d497564?apiKey=35bf81d825094f66b687d157fe963e7b&",
        "https://cdn.builder.io/api/v1/image/assets/TEMP/27567c941a2aa9a043dca986553fd9415fd2d5cbdfdef115d708b06659fa607a?apiKey=35bf81d825094f66b687d157fe963e7b&",
        "https://cdn.builder.io/api/v1/image/assets/TEMP/d272ff6d64d8c5150a0b3463a2b29ec1afeac443c185e28ab21ef48b4c356bb8?apiKey=35bf81d825094f66b687d157fe963e7b&"
    ];

    return (
        <SectionContainer id="about">
            <ContentContainer>
                <ColumnText>
                    <AuthorInfo>
                        <AuthorTitleBadge>Автор курса</AuthorTitleBadge>
                        <AuthorName>Привет, меня зовут Юлия и я помогу тебе выйти на Амазон в США</AuthorName>
                        <AuthorDescription>
                            Мой путь на Amazon не был легким – я столкнулась с множеством трудностей и совершила множество ошибок. В своих курсах я делюсь уникальными тонкостями и лайфхаками, которых нет в бесплатных источниках.
                        </AuthorDescription>
                        <ExperienceList>
                            <ExperienceItem style={{ animationDelay: '0.2s' }}>
                                <ExperienceIconWrapper>
                                    <Icon iconUrl={iconUrls[0]} aria-label="Иконка пути" />
                                </ExperienceIconWrapper>
                                <ExperienceText>Путь от наемного работника до <Highlight>владельца бизнеса на Амазон</Highlight></ExperienceText>
                            </ExperienceItem>
                            <ExperienceItem style={{ animationDelay: '0.35s' }}>
                                <ExperienceIconWrapper>
                                    <Icon iconUrl={iconUrls[1]} aria-label="Иконка брендов" />
                                </ExperienceIconWrapper>
                                <ExperienceText>Помогаю <Highlight>мировым брендам</Highlight> реализовывать стратегии запуска на Амазоне</ExperienceText>
                            </ExperienceItem>
                            <ExperienceItem style={{ animationDelay: '0.5s' }}>
                                <ExperienceIconWrapper>
                                    <Icon iconUrl={iconUrls[2]} aria-label="Иконка арбитража" />
                                </ExperienceIconWrapper>
                                <ExperienceText>Успешно зарабатываю на продажах по модели <Highlight>онлайн арбитраж</Highlight></ExperienceText>
                            </ExperienceItem>
                        </ExperienceList>
                    </AuthorInfo>
                </ColumnText>
                <ColumnImage>
                    <ImageContainer>
                        <AuthorImage loading="eager" src={Julia} alt="Юлия, автор курса Amazon Freedom" />
                        <StatsImage loading="lazy" src={AmazonStats} alt="Пример статистики продаж на Amazon" />
                    </ImageContainer>
                </ColumnImage>
            </ContentContainer>
        </SectionContainer>
    );
};


// --- Styled Components ---

const SectionContainer = styled.section`
    width: 100%;
    max-width: 1240px;
    margin: -100px auto 0 auto; // Negative margin to overlap previous section slightly
    padding: 60px 70px;
    box-sizing: border-box;
    position: relative; // Needed for z-index
    z-index: 10; // Ensure it sits above previous elements if overlapping

    background: linear-gradient(150deg, #08101f 0%, #182c51 100%);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 20px 50px rgba(0, 20, 60, 0.25);
    overflow: hidden; // Important for border-radius and potential inner absolute elements

    @media (max-width: 991px) {
        margin-top: -60px;
        padding: 40px 30px;
        max-width: 95%; // Allow it to shrink a bit more
    }
    @media (max-width: 767px) {
        margin-top: -40px;
        padding: 35px 25px;
        border-radius: 20px;
    }
`;

// --- FIX: Define ColumnText and ColumnImage BEFORE ContentContainer ---
const ColumnText = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%; // Take slightly more than half the width
    color: #fff;
    @media (max-width: 991px) {
        width: 100%; // Take full width when stacked
        align-items: center; // Center content when stacked
        text-align: center;
    }
`;

const ColumnImage = styled.div`
    display: flex;
    width: 45%;
    align-items: center; // Align the container vertically
    justify-content: center; // Center the ImageContainer horizontally
    @media (max-width: 991px) {
        width: 100%; // Take full width
        max-width: 450px; // Limit max width of image column for aesthetics
        margin-bottom: 20px; // Add space below image when stacked
    }
`;
// --- End Fix ---


const ContentContainer = styled.div`
    gap: 70px; // Increased gap for more separation
    display: flex;
    align-items: center; // Vertically align items in the center

    // --- Add entry animations for columns ---
    // Target components using their defined const names
    & > ${ColumnText} { // Target the Text Column
        opacity: 0;
        animation: ${slideInLeft} 0.9s 0.2s ease-out forwards;
    }
    & > ${ColumnImage} { // Target the Image Column
        opacity: 0;
        animation: ${slideInRight} 0.9s 0.2s ease-out forwards;
    }

    @media (max-width: 991px) {
        flex-direction: column-reverse; // Stack image above text on smaller screens
        gap: 40px;
        align-items: stretch; // Let items take full width if needed

        // Disable entry animations on smaller screens where layout changes
        // Target both columns using a common selector or repeating
        & > ${ColumnText},
        & > ${ColumnImage} {
            animation: none;
            opacity: 1;
        }
    }
`;


const AuthorInfo = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
        align-items: center; // Center items within the text column when stacked
    }
`;

const AuthorTitleBadge = styled.div`
    border-radius: 25px;
    border: 1px solid #FF9900;
    align-self: flex-start; // Align to the start (left) by default
    color: #ff9900; // Changed text color to match border
    padding: 8px 18px;
    font: 500 15px/1 'Jost', sans-serif;
    margin-bottom: 20px;
    background-color: rgba(255, 153, 0, 0.1);
    @media (max-width: 991px) {
        align-self: center; // Center badge when content is centered
    }
`;

const AuthorName = styled.h2`
    color: #fff;
    font: 700 46px/1.25 'Bebas Neue', sans-serif; // Using Bebas Neue for impact
    margin: 0 0 25px 0;
    letter-spacing: 0.5px;
    @media (max-width: 1200px) { font-size: 40px; }
    @media (max-width: 991px) { font-size: 36px; }
    @media (max-width: 767px) { font-size: 32px; margin-bottom: 20px; }
`;

const Highlight = styled.span`
    color: #ffc107; // Consistent highlight color
    font-weight: 500; // Slightly bolder highlight
`;

const AuthorDescription = styled.p`
    color: #d5e3ff; // Slightly lighter blue for readability
    margin: 0 0 40px 0;
    font: 400 18px/1.8 'Jost', sans-serif; // Jost for body text
    @media (max-width: 991px) {
        font-size: 16px;
        max-width: 600px; // Limit width for readability when centered
        margin-bottom: 30px;
    }
     @media (max-width: 767px) { font-size: 15px; line-height: 1.7; }
`;

const ExperienceList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    max-width: 520px; // Limit width of the list itself
    @media (max-width: 991px) {
        max-width: 100%; // Allow full width when centered
    }
`;

const ExperienceItem = styled.div`
    border-radius: 12px;
    border: 1px solid rgba(255, 153, 0, 0.4); // Subtle orange border
    display: flex;
    gap: 18px;
    align-items: center;
    padding: 18px;
    background-color: rgba(255, 255, 255, 0.04); // Very subtle background
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    opacity: 0; // Start hidden for animation
    // Apply animation using css helper
    ${css`
      animation: ${fadeInUp} 0.6s ease-out forwards;
    `}
    &:hover {
        background-color: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 153, 0, 0.8); // Stronger border on hover
        transform: translateX(5px); // Slight move right on hover
    }
    @media (max-width: 991px) {
        padding: 15px;
        gap: 15px;
        &:hover {
            transform: translateX(0px); // Disable hover transform on mobile
        }
    }
`;

const ExperienceIconWrapper = styled.div`
    border-radius: 50%;
    background-color: rgba(255, 153, 0, 0.15); // Background for the icon circle
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    padding: 12px; // Padding inside the circle
    flex-shrink: 0; // Prevent wrapper from shrinking
    box-sizing: border-box; // Include padding in size
    @media (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding: 10px;
    }
`;

const Icon = styled.div<{ iconUrl: string }>`
    display: block;
    width: 100%; // Fill the padding area of the wrapper
    height: 100%;
    background-color: #ffc107; // Set the desired icon color (consistent yellow)
    mask-image: url(${({ iconUrl }) => iconUrl});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-image: url(${({ iconUrl }) => iconUrl}); // Safari/WebKit support
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
`;

const ExperienceText = styled.p`
    color: #e0eaff; // Lighter text color
    margin: 0;
    font: 400 16px/1.6 'Jost', sans-serif;
    flex-grow: 1; // Allow text to take remaining space
    @media (max-width: 991px) {
        font-size: 15px;
        text-align: left; // Ensure text aligns left even if parent is centered
    }
`;

const ImageContainer = styled.div`
    border-radius: 18px;
    background: linear-gradient(116deg, #f90 5.3%, #ffd494 94.16%); // Gradient background
    width: 100%;
    aspect-ratio: 3 / 4; // Portrait aspect ratio
    position: relative;
    overflow: hidden;
    max-height: 600px;
    box-shadow: 0 10px 30px rgba(0,0,0, 0.25);
    transition: transform 0.4s ease; // Add transition for potential hover effects

    @media (max-width: 991px) {
        aspect-ratio: 1 / 1; // Square aspect ratio when stacked
        max-height: 450px;
        border-radius: 16px;
    }
    @media (max-width: 767px) {
        max-height: 400px;
    }
`;


const AuthorImage = styled.img`
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 120%; // Allow image to be slightly wider than container for effect
    height: auto;
    max-height: 100%;
    object-fit: contain; // Ensure image scales down correctly
    object-position: bottom center;

    // Animation
    transform: translateX(-50%) scale(1.1);
    opacity: 0;
    animation: ${scaleIn} 1s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards; // Smoother ease
`;

const StatsImage = styled.img`
    position: absolute;
    bottom: 25px;
    right: -15px; // Position slightly outside the container
    width: 55%;
    max-width: 230px;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.4s ease; // Bouncy transition

    // Animation
    transform: rotate(4deg) scale(0.9);
    opacity: 0;
    animation: ${popIn} 0.6s 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards; // Bouncy ease

    // Hover effect triggered by parent container hover
    ${ImageContainer}:hover & {
        transform: rotate(0deg) scale(1.05); // Pop out slightly on hover
        opacity: 1; // Ensure it's visible on hover if needed
    }

    @media (max-width: 1200px) {
        width: 50%;
        right: -10px;
        bottom: 20px;
    }
    @media (max-width: 991px) {
        width: 45%;
        max-width: 180px;
        bottom: 15px;
        right: 15px; // Bring inside container when stacked
        // Reset animation/transform for mobile if desired, or keep popIn
        transform: rotate(0deg) scale(1);
        opacity: 1; // Make sure it's visible
        animation: none; // Disable entry animation on mobile
        ${ImageContainer}:hover & {
            transform: scale(1.05); // Simple scale hover on mobile
        }
    }
    @media (max-width: 767px) {
        display: none; // Hide stats image on very small screens
    }
`;

export default CourseAuthor;