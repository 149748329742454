import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Users } from 'react-feather';

// Import images (ensure paths are correct)
import Img2 from '../assets/photo/image2.jpg'; // Example: Discussion/Teamwork
import Img5 from '../assets/photo/image5.jpg'; // Example: Amazon Package/Result
import Img6 from '../assets/photo/image6.jpg'; // Example: Laptop Lifestyle
// --- Add more relevant images if available ---
// import ImgLaptopBeach from '../assets/photo/laptop_beach.jpg';
// import ImgProductExample from '../assets/photo/product_example.jpg';

// --- Keyframes for Animations ---
const slideUp = keyframes`
  from { transform: translateY(40px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const scaleUp = keyframes`
  from { transform: scale(0.9) translateY(10px); opacity: 0; }
  to { transform: scale(1) translateY(0); opacity: 1; }
`;

// --- Hero Section Component ---
const HeroSection: React.FC = () => {

    const stripImages = [
        { src: Img6, alt: "Работа над бизнесом Amazon с ноутбука" },
        { src: Img5, alt: "Упакованный товар для отправки на Amazon" },
        { src: Img2, alt: "Обсуждение стратегии продаж на Amazon" },
        // { src: ImgLaptopBeach, alt: "Свободный график и работа у моря" },
        // { src: ImgProductExample, alt: "Пример успешного товара на Amazon" },
    ];

    return (
        <HeroContainer>
            <BackgroundOverlay />
            <HeroContent>
                {/* --- ИЗМЕНЕН КОНТЕЙНЕР ДЛЯ РАЗМЕЩЕНИЯ ЗАГОЛОВКА/ПОДЗАГОЛОВКА --- */}
                <HeaderTextContainer>
                    {/* --- ИЗМЕНЕННЫЙ ЗАГОЛОВОК С ЖЕЛТЫМ $$$ --- */}
                    <Title>Обретите свободу и начните зарабатывать в <MoneyHighlight>$$$</MoneyHighlight> из любой точки мира</Title>
                    {/* --- ИЗМЕНЕННЫЙ ПОДЗАГОЛОВОК (продающий) --- */}
                    <Subtitle>
                        Превратите свой Amazon-бизнес в машину по заработку <MoneyHighlight>$$$</MoneyHighlight>! Получите проверенные стратегии для кратного роста продаж и масштабирования из любой точки мира.
                    </Subtitle>
                </HeaderTextContainer>

                {/* Кнопка теперь под текстом, центрирована */}
                <CtaWrapper>
                    <CtaButton href="#courses">Хочу свой бизнес на Amazon!</CtaButton>
                </CtaWrapper>

                <StatsContainer>
                    <StatItem $delay="0.8s"><strong>$1000+</strong> Доход учеников в первый месяц</StatItem>
                    <StatItem $delay="1.0s">
                        <StatIconWrapper><Users size={28} /></StatIconWrapper>
                        <strong>100+</strong> Успешных выпускников
                    </StatItem>
                    <StatItem $delay="1.2s"><strong>90%</strong> Окупают курс за 1-3 месяца</StatItem>
                </StatsContainer>
            </HeroContent>

            <ImageStripContainer>
                <ImageStripContent>
                    {stripImages.map((image, index) => (
                        <StripImageWrapper
                            key={index}
                            $animationDelay={`${1.0 + index * 0.15}s`} // Слегка увеличим задержку для картинок
                        >
                            <StripImage src={image.src} alt={image.alt} loading={index < 2 ? "eager" : "lazy"} />
                        </StripImageWrapper>
                    ))}
                </ImageStripContent>
            </ImageStripContainer>
        </HeroContainer>
    );
};

// --- Styled Components ---

// Main container
const HeroContainer = styled.section`
  background: linear-gradient(135deg, #001B4F 0%, #00337C 100%);
  color: #fff; padding: 120px 0 0; position: relative; overflow: hidden;
  display: flex; flex-direction: column; align-items: center; min-height: 90vh; // Сделаем чуть выше
  @media (max-width: 991px) { padding-top: 100px; min-height: auto; }
`;

// Overlay
const BackgroundOverlay = styled.div`
  position: absolute; top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 10, 30, 0.2); z-index: 1;
`;

// Content wrapper for text and stats
const HeroContent = styled.div`
  max-width: 1100px; // Увеличим немного максимальную ширину для нового layout
  width: 100%; padding: 0 20px; box-sizing: border-box;
  display: flex; flex-direction: column; align-items: center; // Центрируем все содержимое
  position: relative; z-index: 2; margin-bottom: 70px;
  @media (max-width: 991px) { margin-bottom: 60px; padding: 0 15px; max-width: 95%; }
  @media (max-width: 767px) { margin-bottom: 50px; }
`;

// Header Text container with animation - ТЕПЕРЬ FLEX ДЛЯ РАЗМЕЩЕНИЯ
const HeaderTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; // Распределяем элементы по краям
  align-items: flex-start; // Выравниваем по верху
  gap: 40px; // Пространство между заголовком и подзаголовком
  margin-bottom: 45px; // Отступ до кнопки CTA
  opacity: 0;
  animation: ${slideUp} 0.8s 0.1s ease-out forwards;

  @media (max-width: 991px) {
    flex-direction: column; // Стекируем на средних экранах
    align-items: center; // Центрируем стекированные элементы
    text-align: center; // Центрируем текст в стекированном виде
    gap: 25px;
    margin-bottom: 40px;
  }
`;

// Title - ТЕПЕРЬ СЛЕВА
const Title = styled.h1`
  font-family: 'Bebas Neue', sans-serif; font-size: 68px; line-height: 1.15; font-weight: 700;
  margin: 0; letter-spacing: 1px; color: #ffffff;
  flex: 1 1 55%; // Занимает чуть больше половины доступного пространства
  text-align: left; // Выравнивание текста влево

  @media (max-width: 1100px) { font-size: 60px; } // Адаптация размера шрифта
  @media (max-width: 991px) {
    font-size: 54px;
    text-align: center; // Центрируем текст на меньших экранах
    flex-basis: auto; // Сбрасываем flex-basis
  }
  @media (max-width: 767px) { font-size: 44px; line-height: 1.15;}
  @media (max-width: 480px) { font-size: 38px; }
`;

// Highlight (оставлен)
const Highlight = styled.span` color: #FF9900; `;

// Money Highlight Span - ДЛЯ ЖЕЛТОГО $$$
const MoneyHighlight = styled.span`
  color: #ffc107; // Используем желтый цвет как в статистике
`;

// Subtitle - ТЕПЕРЬ СПРАВА
const Subtitle = styled.p`
  font-family: 'Jost', sans-serif; font-size: 19px; line-height: 1.7; margin: 5px 0 0 0; // Небольшой отступ сверху для выравнивания
  opacity: 0.9;
  flex: 1 1 40%; // Занимает чуть меньше половины
  max-width: 450px; // Ограничиваем максимальную ширину для читаемости
  text-align: left; // Выравнивание текста влево

  @media (max-width: 1100px) { font-size: 18px; } // Адаптация размера шрифта
   @media (max-width: 991px) {
    font-size: 18px;
    text-align: center; // Центрируем текст на меньших экранах
    max-width: 600px; // Увеличиваем max-width в стекированном виде
    flex-basis: auto; // Сбрасываем flex-basis
    margin-top: 0;
  }
  @media (max-width: 767px) { font-size: 17px; line-height: 1.6;}
`;

// Wrapper for CTA button to center it independently
const CtaWrapper = styled.div`
    width: 100%;
    text-align: center; // Центрируем кнопку
    margin-bottom: 60px; // Отступ до статистики
    opacity: 0; animation: ${fadeIn} 0.8s 0.4s ease-out forwards;
    @media (max-width: 991px) { margin-bottom: 50px; }
`;

// CTA Button
const CtaButton = styled.a`
  display: inline-block; background-color: #ff9900; color: #fff;
  padding: 16px 45px; border-radius: 50px; font-family: 'Jost', sans-serif;
  font-size: 18px; font-weight: 700; text-decoration: none;
  transition: all 0.3s ease; box-shadow: 0 5px 15px rgba(255, 153, 0, 0.3);
  /* Анимация перенесена в CtaWrapper */
  &:hover {
    background-color: #e08900; transform: translateY(-3px) scale(1.03);
    box-shadow: 0 8px 20px rgba(255, 153, 0, 0.4);
  }
  &:active { transform: translateY(0px) scale(0.98); }
  @media (max-width: 767px) { font-size: 16px; padding: 14px 35px; }
`;

// Container for Stats
const StatsContainer = styled.div`
  width: 100%;
  display: flex; gap: 25px 40px; justify-content: center; flex-wrap: wrap;
  margin-top: 0; // Убрали верхний отступ, т.к. он теперь у CtaWrapper
  position: relative; z-index: 3;
  @media (max-width: 767px) { gap: 20px 30px; }
`;

// Individual Stat Item with animation
const StatItem = styled.div<{ $delay: string }>`
  font-family: 'Jost', sans-serif; font-size: 16px; line-height: 1.4;
  text-align: center; color: #d0e1ff; opacity: 0;
  animation: ${scaleUp} 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation-delay: ${({ $delay }) => $delay};
  strong {
    display: block; font-size: 28px; font-weight: 700; color: #ffc107; margin-bottom: 6px;
  }
  &:has(> strong:contains('100+')) {
    display: flex; flex-direction: column; align-items: center;
    strong { margin-bottom: 6px; }
  }
  @media (max-width: 767px) { font-size: 14px; strong { font-size: 24px; margin-bottom: 4px;} }
  @media (max-width: 480px) { flex-basis: 45%; font-size: 13px; strong { font-size: 22px;} }
`;

// Icon Wrapper for Stat
const StatIconWrapper = styled.span`
    display: block; margin-bottom: 8px; color: #a7cfff;
    svg { width: 28px; height: 28px;
        @media (max-width: 767px) { width: 24px; height: 24px; }
    }
`;

// Container for the horizontal image strip
const ImageStripContainer = styled.div`
  width: 100%;
  margin-top: auto; // Прижимаем к низу HeroContainer
  position: relative;
  z-index: 2;
  padding: 10px 150px 80px 150px; // top horizontal bottom horizontal
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar { display: none; }
  scrollbar-width: none;
  -ms-overflow-style: none;

  mask-image: linear-gradient(to right, transparent, black 150px, black calc(100% - 150px), transparent);
  -webkit-mask-image: linear-gradient(to right, transparent, black 150px, black calc(100% - 150px), transparent);

  @media (max-width: 991px) {
    padding: 10px 100px 60px 100px;
    mask-image: linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent);
  }
  @media (max-width: 767px) {
    padding: 10px 60px 50px 60px;
    mask-image: linear-gradient(to right, transparent, black 60px, black calc(100% - 60px), transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 60px, black calc(100% - 60px), transparent);
  }
  @media (max-width: 480px) {
    padding: 10px 30px 50px 30px;
    mask-image: linear-gradient(to right, transparent, black 30px, black calc(100% - 30px), transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 30px, black calc(100% - 30px), transparent);
  }
`;

// Inner content div that holds images
const ImageStripContent = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 0;
  margin: 0;

  @media (max-width: 991px) { gap: 20px; }
  @media (max-width: 767px) { gap: 15px; }
`;

// Wrapper for each image in the strip with individual animation
const StripImageWrapper = styled.div<{ $animationDelay: string }>`
  flex-shrink: 0;
  width: 300px; aspect-ratio: 16 / 10; height: auto;
  border-radius: 12px; overflow: hidden; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.25);
  transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0; transform: scale(0.9) translateY(10px);
  animation: ${scaleUp} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation-delay: ${({ $animationDelay }) => $animationDelay};
  &:hover { transform: scale(1.05) translateY(-5px); }
  @media (max-width: 991px) { width: 260px; }
  @media (max-width: 767px) { width: 220px; }
  @media (max-width: 480px) { width: 190px; }
`;

// The image itself
const StripImage = styled.img`
  display: block; width: 100%; height: 100%; object-fit: cover;
`;

export default HeroSection;