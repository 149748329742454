import React, { useEffect, useState, useMemo } from 'react';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';

export interface IOpportunity {
    id: number;
    category: string;
    asin?: string;
    product_name?: string;
    purchase_price: number;
    sale_price: number;
    profit: number;
    roi: number;
    buy_link: string;
    amazon_link: string | null;
    product_code: string | null;
    has_us_shipping: boolean;
    rank: number | null;
    monthly_sales?: number;
    offer_count?: number;
    variations?: number;
    fba_fee?: number;
    pick_pack_fee?: number;
    ship_to_amazon?: number;
    description: string | null;
    is_flammable: boolean;
    photos: string[];
}

const Opportunities: React.FC = () => {
    const { token } = useAuth();
    const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Фильтры и сортировка
    const [filterCategory, setFilterCategory] = useState<string>('all');
    const [sortField, setSortField] = useState<string>('profit');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Состояния для отчёта товара (report)
    const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);
    const [reportOpportunityId, setReportOpportunityId] = useState<number | null>(null);
    const [reportComment, setReportComment] = useState<string>('');

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const response = await fetch('/api/opportunities', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Ошибка при загрузке вилок');
                }
                const data = await response.json();
                // Ожидается структура { data: IOpportunity[], meta: { ... } }
                setOpportunities(data.data);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchOpportunities();
    }, [token]);

    // Получаем уникальные категории
    const categories = useMemo(() => {
        const cats = opportunities.map(opp => opp.category);
        return Array.from(new Set(cats));
    }, [opportunities]);

    // Применяем фильтры и сортировку
    const filteredOpportunities = useMemo(() => {
        let data = [...opportunities];
        if (filterCategory !== 'all') {
            data = data.filter(opp => opp.category === filterCategory);
        }
        if (searchTerm.trim() !== '') {
            data = data.filter(opp =>
                (opp.product_name && opp.product_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (opp.asin && opp.asin.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
        data.sort((a, b) => {
            let aVal: number | string = (a as any)[sortField];
            let bVal: number | string = (b as any)[sortField];
            if (typeof aVal === 'string') {
                aVal = aVal.toLowerCase();
                bVal = (bVal as string).toLowerCase();
            }
            if (aVal < bVal) return sortDirection === 'asc' ? -1 : 1;
            if (aVal > bVal) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
        return data;
    }, [opportunities, filterCategory, searchTerm, sortField, sortDirection]);

    const openReportModal = (oppId: number) => {
        setReportOpportunityId(oppId);
        setReportComment('');
        setReportModalOpen(true);
    };

    const closeReportModal = () => {
        setReportModalOpen(false);
    };

    const handleReportSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!reportOpportunityId) return;
        try {
            const response = await fetch(`/api/opportunities/${reportOpportunityId}/report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ comment: reportComment }),
            });
            if (!response.ok) {
                throw new Error('Ошибка при отправке отчёта');
            }
            alert('Отчёт отправлен');
            closeReportModal();
        } catch (err: any) {
            alert(err.message);
        }
    };

    if (loading) {
        return <LoadingContainer>Загрузка...</LoadingContainer>;
    }

    if (error) {
        return <ErrorContainer>{error}</ErrorContainer>;
    }

    return (
        <Layout>
            <PageTitle>Вилки (Профитные товары)</PageTitle>
            <FilterContainer>
                <FilterGroup>
                    <Label>Категория:</Label>
                    <Select value={filterCategory} onChange={(e) => setFilterCategory(e.target.value)}>
                        <option value="all">Все категории</option>
                        {categories.map((cat, idx) => (
                            <option key={idx} value={cat}>{cat}</option>
                        ))}
                    </Select>
                </FilterGroup>
                <FilterGroup>
                    <Label>Сортировать по:</Label>
                    <Select value={sortField} onChange={(e) => setSortField(e.target.value)}>
                        <option value="profit">Профит</option>
                        <option value="roi">ROI (%)</option>
                        <option value="sale_price">Цена продажи</option>
                        <option value="purchase_price">Цена закупки</option>
                    </Select>
                    <SortToggle onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}>
                        {sortDirection === 'asc' ? '↑' : '↓'}
                    </SortToggle>
                </FilterGroup>
                <FilterGroup>
                    <Label>Поиск:</Label>
                    <SearchInput
                        type="text"
                        placeholder="Название или ASIN"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </FilterGroup>
            </FilterContainer>

            {filteredOpportunities.length === 0 ? (
                <NoOpportunitiesMessage>В данный момент нет доступных вилок.</NoOpportunitiesMessage>
            ) : (
                <OpportunityList>
                    {filteredOpportunities.map(opp => (
                        <OpportunityItem key={opp.id}>
                            {opp.photos && opp.photos.length > 0 ? (
                                <OpportunityImage src={opp.photos[0]} alt={opp.category} />
                            ) : (
                                <PlaceholderImage>Нет фото</PlaceholderImage>
                            )}
                            <OpportunityDetails>
                                <OpportunityHeader>
                                    <OpportunityName>{opp.product_name || 'Без названия'}</OpportunityName>
                                    <ReportButton onClick={() => openReportModal(opp.id)}>Сообщить</ReportButton>
                                </OpportunityHeader>
                                <OpportunityCategoryText>Категория: {opp.category}</OpportunityCategoryText>
                                {opp.description && (
                                    <OpportunityDescription>{opp.description}</OpportunityDescription>
                                )}
                                <OpportunityPrices>
                                    <PriceGroup>
                                        <PriceLabel>Закупка:</PriceLabel>
                                        <PriceValue>${opp.purchase_price.toFixed(2)}</PriceValue>
                                    </PriceGroup>
                                    <PriceGroup>
                                        <PriceLabel>Продажа:</PriceLabel>
                                        <PriceValue>${opp.sale_price.toFixed(2)}</PriceValue>
                                    </PriceGroup>
                                    <PriceGroup>
                                        <PriceLabel>Профит:</PriceLabel>
                                        <PriceValue style={{ color: opp.profit >= 0 ? 'green' : 'red' }}>
                                            ${opp.profit.toFixed(2)}
                                        </PriceValue>
                                    </PriceGroup>
                                    <PriceGroup>
                                        <PriceLabel>ROI:</PriceLabel>
                                        <PriceValue style={{ color: opp.roi >= 0 ? 'green' : 'red' }}>
                                            {opp.roi.toFixed(2)}%
                                        </PriceValue>
                                    </PriceGroup>
                                </OpportunityPrices>
                                <OpportunityLinks>
                                    <LinkButton href={opp.buy_link} target="_blank" rel="noreferrer">
                                        Купить
                                    </LinkButton>
                                    {opp.amazon_link && (
                                        <LinkButton href={opp.amazon_link} target="_blank" rel="noreferrer">
                                            Amazon
                                        </LinkButton>
                                    )}
                                </OpportunityLinks>
                            </OpportunityDetails>
                        </OpportunityItem>
                    ))}
                </OpportunityList>
            )}

            {reportModalOpen && (
                <ModalOverlay onClick={closeReportModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <ModalClose onClick={closeReportModal}>&times;</ModalClose>
                        <ModalTitle>Сообщить о товаре</ModalTitle>
                        <ModalForm onSubmit={handleReportSubmit}>
                            <ModalTextarea
                                placeholder="Введите комментарий..."
                                value={reportComment}
                                onChange={(e) => setReportComment(e.target.value)}
                                required
                            />
                            <ModalSubmit type="submit">Отправить</ModalSubmit>
                        </ModalForm>
                    </ModalContent>
                </ModalOverlay>
            )}
        </Layout>
    );
};

const PageTitle = styled.h1`
  color: #001B4F;
  font-size: 32px;
  margin-bottom: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  background-color: #f1f8ff;
  padding: 20px;
  border-radius: 12px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  color: #001B4F;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Select = styled.select`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const SortToggle = styled.button`
  margin-top: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #ff9900;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const OpportunityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OpportunityItem = styled.div`
  display: flex;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const OpportunityImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
`;

const PlaceholderImage = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ddd;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 20px;
`;

const OpportunityDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OpportunityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OpportunityName = styled.h2`
  font-size: 20px;
  color: #001B4F;
  margin: 0;
`;

const ReportButton = styled.button`
  background-color: #d32f2f;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #c62828;
  }
`;

const OpportunityCategoryText = styled.h3`
  font-size: 18px;
  color: #001B4F;
  margin: 0;
`;

const OpportunityDescription = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
`;

const OpportunityPrices = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 16px;
`;

const PriceGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PriceLabel = styled.span`
  font-weight: bold;
`;

const PriceValue = styled.span`
  color: #000;
`;

const OpportunityLinks = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const LinkButton = styled.a`
  background-color: #ff9900;
  color: #fff;
  padding: 10px 20px;
  border-radius: 24px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ff8c00;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: 24px;
`;

const ErrorContainer = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: 24px;
  color: red;
`;

const NoOpportunitiesMessage = styled.p`
  font-size: 18px;
  color: #333;
`;

// Модальное окно для отчёта
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #fff;
  color: #001B4F;
  padding: 30px;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ModalTextarea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: vertical;
`;

const ModalSubmit = styled.button`
  background-color: #ff9900;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background-color: #ff8c00;
  }
`;

export default Opportunities;
