import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import { User, TrendingUp, DollarSign } from 'react-feather';

// --- Keyframe Animation ---
const pulseShadow = keyframes`
  0% {
    box-shadow: 0 8px 25px rgba(255, 193, 7, 0.2), 0 0 0 0 rgba(255, 193, 7, 0.4);
    border-color: #ffc107;
  }
  70% {
    box-shadow: 0 8px 25px rgba(255, 193, 7, 0.2), 0 0 0 10px rgba(255, 193, 7, 0);
    border-color: #ffe088; // Slightly lighter border during pulse
  }
  100% {
    box-shadow: 0 8px 25px rgba(255, 193, 7, 0.2), 0 0 0 0 rgba(255, 193, 7, 0);
    border-color: #ffc107;
  }
`;

// --- Component ---
const ForWho: React.FC = () => {
  return (
    <SectionWrapper>
      <Container id="forwho">
        <Header>
          {/* --- ИЗМЕНЕННЫЙ ЗАГОЛОВОК --- */}
          <Title>Кому идеально подойдет <Highlight>запуск бизнеса</Highlight> на Amazon?</Title>
          {/* --- СЛЕГКА ИЗМЕНЕННОЕ ОПИСАНИЕ --- */}
          <Description>
            Этот курс создан для тех, кто готов изменить свою жизнь, построить <Highlight>независимый международный бизнес</Highlight> и получать стабильный доход в <YellowText>$$$</YellowText>.
          </Description>
        </Header>
        <Divider />
        <Content>
          <Row>
            {/* Card 1: Novices */}
            <Card>
              <CardIconWrapper color="#007bff"> <User size={48} /> </CardIconWrapper>
              <CardContent>
                <CardTitle>Новичкам без опыта</CardTitle>
                {/* --- Текст описания оставлен без изменений --- */}
                <CardDescription>
                  Даже если вы никогда не продавали онлайн, курс проведет вас <Highlight>с абсолютного нуля</Highlight> до первых $$$ на Amazon. Все шаги объясняются просто и понятно.
                </CardDescription>
              </CardContent>
            </Card>

            {/* Card 2: Existing Sellers - Featured */}
            <Card $featured={true}>
              <CardIconWrapper color="#ff9900"> <TrendingUp size={48} /> </CardIconWrapper>
              <CardContent>
                <CardTitle>Действующим селлерам</CardTitle>
                {/* --- Текст описания оставлен без изменений --- */}
                <CardDescription>
                  Устали от конкуренции и ограничений локальных площадок? <Highlight>Масштабируйтесь</Highlight> на мировой рынок Amazon и увеличьте свой доход в разы.
                </CardDescription>
              </CardContent>
            </Card>

            {/* Card 3: Investors */}
            <Card>
              <CardIconWrapper color="#28a745"> <DollarSign size={48} /> </CardIconWrapper>
              <CardContent>
                <CardTitle>Инвесторам</CardTitle>
                {/* --- Текст описания оставлен без изменений --- */}
                <CardDescription>
                  Ищете <Highlight>надежный актив</Highlight> с высокой доходностью? Бизнес на Amazon с маржинальностью от 20% в USD – отличная диверсификация портфеля.
                </CardDescription>
              </CardContent>
            </Card>
          </Row>
        </Content>
      </Container>
    </SectionWrapper>
  );
}

// --- Styled Components ---
const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 90px 20px;
  background: linear-gradient(160deg, #050c1a 0%, #142647 100%);
  box-sizing: border-box;
  color: #fff;
  @media (max-width: 991px) {
    padding: 70px 15px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1240px;
  flex-direction: column;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 850px; // Limit header width for readability
  margin-bottom: 50px;
  text-align: center;
`;

const Title = styled.h2`
  font: 700 60px/1.1 'Bebas Neue', sans-serif;
  margin: 0 0 20px 0;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    font-size: 48px;
  }
  @media (max-width: 767px) {
    font-size: 40px;
  }
`;

const Description = styled.p`
  font: 400 19px/1.7 'Jost', sans-serif;
  opacity: 0.9;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 17px;
  }
`;

const YellowText = styled.span`
  color: #FF9900; // Keeping this color for $$$
`;

const Highlight = styled.span`
  color: #a7cfff; // Light blue highlight color
  font-weight: 500;
`;

const Divider = styled.div`
  background: linear-gradient(90deg, rgba(255,153,0,0) 0%, rgba(255,153,0,0.5) 50%, rgba(255,153,0,0) 100%);
  margin-bottom: 70px;
  width: 100%;
  max-width: 300px;
  height: 2px;
  border: none;
  @media (max-width: 991px) {
    margin-bottom: 50px;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  gap: 35px; // The space between cards
  justify-content: center;
  align-items: stretch; // Make cards same height if content differs
  /* flex-wrap: wrap; */ // REMOVE wrap on desktop to force single line
  @media (max-width: 991px) {
    flex-wrap: wrap; // Allow wrapping on tablets/mobiles
  }
`;

const Card = styled.div<{ $featured?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  // --- FIX: Calculate width to fit 3 cards with the gap ---
  width: calc((100% - 70px) / 3); // 70px = 2 * 35px gap
  max-width: 390px; // Optional: Set a max-width for large screens

  background: #fff;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  color: #333;
  padding: 30px 25px;
  border: 2px solid transparent; // Base transparent border

  ${({ $featured }) => $featured && css`
     border-color: #ffc107;
     transform: scale(1.02);
     animation: ${pulseShadow} 2.5s infinite ease-in-out;
  `}

  &:hover {
    transform: translateY(-10px) scale(${({ $featured }) => $featured ? '1.04' : '1.02'});
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 991px) { // Adjust for 2 cards per row on tablets
    width: calc(50% - 18px); // 18px = 35px gap / 2 (approx)
    max-width: none; // Remove max-width limit for tablet layout
  }
  @media (max-width: 600px) { // Stack to 1 card per row on mobile
    width: 100%;
    max-width: 400px; // Optional max-width for single column
  }
`;

const CardIconWrapper = styled.div<{ color?: string }>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${props => props.color || '#004ee4'}1A; // Color with low opacity
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  color: ${props => props.color || '#004ee4'}; // Icon color
  flex-shrink: 0;
  transition: transform 0.3s ease;
  ${Card}:hover & {
    transform: scale(1.1);
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allows description to push title up if needed
  text-align: center;
  width: 100%;
`;

const CardTitle = styled.h3`
  color: #0e0b2c;
  font: 700 24px/1.3 'Jost', sans-serif;
  margin: 0 auto 15px auto;
  max-width: 95%;
  @media (max-width: 991px) {
    font-size: 22px;
  }
`;

const CardDescription = styled.p`
  color: #444;
  font: 400 16px/1.7 'Jost', sans-serif;
  flex-grow: 1;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 15px;
  }
`;

export default ForWho;