import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

// --- Icons ---
// Keeping the CheckCircleIcon from the refined version
const CheckCircleIcon = () => <span style={{ color: '#28a745', marginRight: '8px' }}>✔</span>;

// --- Stripe Initialization ---
// IMPORTANT: Use your ACTUAL Stripe publishable key (use environment variable)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_YOUR_TEST_KEY'); // Replace with your key

// --- Interface (Ensuring all fields from both versions are covered) ---
interface Course {
  id: number;
  name: string;
  internal_name: string; // Used for mapping details if not from API
  // Fields from 'new' design (may overlap or be derived)
  tagline?: string;       // Optional: Can be derived or added to API
  description?: string;   // Optional: Can be derived or added to API
  featured?: boolean;     // Keep this for styling
  expected_income?: string;// Keep this if needed, otherwise use old logic
  details?: string[];     // Keep this if needed, otherwise use old logic
  // Fields specifically from 'old' design needed for display
  price_usd: number;
  price_rub: number;
  discounted_price_usd: number;
  discounted_price_rub: number;
  discount_end_date: string | null; // Allow null
  discount_percentage: number;
  active: boolean;
  currency?: 'usd' | 'rub'; // Added dynamically in modal
}

// --- Course Details Mapping (From original logic) ---
const courseDetails: { [key: string]: string[] } = {
  'Course 1': ['Этот курс находится в разработке.', 'Курс пока недоступен'],
  'Course 2': [
    'Открытие компании в США (регистрация LLC, получение налоговых номеров, подготовка к работе на Amazon)',
    'Основы работы с Sellercentral (настройка аккаунта, навигация по интерфейсу, управление продажами)',
    'Поиск поставщиков (выбор надежных поставщиков, анализ прайс-листов, оптимизация закупок)',
    'Методы поиска прибыльных товаров + 🎁 подарок товар для старта (стратегии нахождения товаров, анализ конкурентов, использование инструментов для оценки спроса)',
    'Основы работы с 3PL (взаимодействие с логистическими центрами, оптимизация цепочек поставок)',
    'Юнит экономика (расчет себестоимости, ROI, прибыль на единицу, финансовое планирование)',
    'Отправка товара на склад Амазона (создание шипментов, выбор FBA или FBM, работа с преп-центрами)'
  ],
  'Course 3': ['Этот курс находится в разработке.', 'Курс пока недоступен'],
};
// --- End Course Details ---

// --- Component ---
const CourseSection: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [errorCourses, setErrorCourses] = useState<string | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [modalType, setModalType] = useState<'purchase' | 'notify' | null>(null);
  const [emailForNotify, setEmailForNotify] = useState('');
  const [sourceTag, setSourceTag] = useState<string | null>(null);

  // Fetch courses and source tag on mount
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tagFromUrl = urlParams.get('source_tag');
    const tagFromCookie = document.cookie.split('; ').find(row => row.startsWith('source_tag='))?.split('=')[1];

    if (tagFromUrl) {
      setSourceTag(tagFromUrl);
      document.cookie = `source_tag=${tagFromUrl}; path=/; max-age=2592000; SameSite=Lax`;
    } else if (tagFromCookie) {
      setSourceTag(tagFromCookie);
    }

    setLoadingCourses(true);
    // Fetch assuming API returns data matching the original Course interface + internal_name
    axios.get<Omit<Course, 'details' | 'featured' | 'currency' | 'tagline' | 'description' | 'expected_income'>[]>('/api/courses')
      .then(response => {
        // Enrich with details and featured status based on original logic
        const enrichedCourses = response.data.map(course => ({
          ...course,
          details: courseDetails[course.internal_name] || ['Подробности скоро появятся...'],
          featured: course.internal_name === 'Course 2', // Example feature logic
          // Add defaults or derive other 'new' fields if needed by modals, otherwise they can be optional
          tagline: course.internal_name === 'Course 2' ? 'Старт бизнеса на Amazon' : 'Подробности скоро', // Example
          description: course.internal_name === 'Course 2' ? 'Полный курс для старта...' : 'Скоро...', // Example
          expected_income: course.id === 1 ? "800–2000$" : "от 1000$", // Example derived
        }));
        setCourses(enrichedCourses as Course[]);
        setErrorCourses(null);
      })
      .catch(err => {
        console.error("Error fetching courses:", err);
        setErrorCourses('Не удалось загрузить курсы. Попробуйте обновить страницу.');
      })
      .finally(() => {
        setLoadingCourses(false);
      });

  }, []);

  // --- Modal Handling (Using refined logic) ---
  const toggleBodyScroll = (disable: boolean) => {
    document.body.style.overflow = disable ? 'hidden' : '';
  };

  // This function now handles triggering both modal types
  const handleOpenModal = (course: Course, type: 'purchase' | 'notify', currency?: 'usd' | 'rub') => {
    setSelectedCourse({ ...course, currency }); // Pass currency for purchase modal
    setModalType(type);
    toggleBodyScroll(true);
  };

  const handleCloseModal = () => {
    setSelectedCourse(null);
    setModalType(null);
    setEmailForNotify('');
    toggleBodyScroll(false);
  };

  // Handles email submission from the Notify Modal
  const handleSubmitEmail = () => {
    if (selectedCourse && emailForNotify) {
      if (!/\S+@\S+\.\S+/.test(emailForNotify)) {
        alert('Пожалуйста, введите корректный email.');
        return;
      }
      console.log(`Submitting notification request for course ${selectedCourse.id} with email ${emailForNotify}`);
      axios.post('/api/notify', { courseId: selectedCourse.id, email: emailForNotify })
        .then(() => {
          alert('Спасибо! Мы сообщим вам, когда курс станет доступен.');
          handleCloseModal();
        })
        .catch(err => {
          console.error("Error submitting notification email:", err);
          alert('Произошла ошибка. Попробуйте еще раз.');
        });
    } else {
      alert('Пожалуйста, введите ваш email.');
    }
  };

  // --- Render Logic ---
  if (loadingCourses) {
    return <Section id="courses"><LoadingText>Загрузка курсов...</LoadingText></Section>;
  }

  if (errorCourses) {
    return <Section id="courses"><ErrorTextStyled>{errorCourses}</ErrorTextStyled></Section>;
  }

  return (
    <Section id="courses">
      {/* Using refined Section Title */}
      <SectionTitle>ВЫБЕРИТЕ СВОЙ ПУТЬ К <HighlightText>СВОБОДЕ</HighlightText> НА AMAZON</SectionTitle>
      <Subtitle>Начните зарабатывать в $$$ уже через несколько недель после старта обучения!</Subtitle>
      <CoursesContainer>
        {courses
          // Keep sorting from refined version
          .sort((a, b) => (a.featured ? -1 : 1) - (b.featured ? -1 : 1) || a.id - b.id)
          .map(course => (
            // Using refined Card styling
            <CourseCard key={course.id} active={course.active} featured={course.featured}>
              {/* Badges from refined version */}
              {course.featured && <FeaturedBadge>Хит продаж</FeaturedBadge>}
              {/* Discount badge using original logic/data */}
              {course.discount_percentage > 0 && <DiscountBadgeOriginal>-{course.discount_percentage}%</DiscountBadgeOriginal>}

              {/* Using original Header structure and content */}
              <CourseHeaderOriginal>
                <ColumnOriginal>
                  <CourseTitleOriginal>{course.name}</CourseTitleOriginal>
                  {/* Subtitle logic from original */}
                  <CourseSubtitleOriginal>
                    {course.id === 1 && 'Подходит тем, кто хочет освоить азы Амазона и работать на селлеров'}
                    {course.id === 2 && 'Подходит тем, кто хочет открыть свой бизнес по системе онлайн арбитраж'}
                    {course.id === 3 && 'Подходит тем, кто хочет создать свой бренд с нуля и запустить его на Амазон'}
                  </CourseSubtitleOriginal>
                </ColumnOriginal>
                {/* Column2 was just for the badge in original, handled above */}
              </CourseHeaderOriginal>

              {/* Content Title from refined */}
              {course.active && <CourseContentTitle>Что вы получите:</CourseContentTitle>}

              {/* Content List using original data source, refined styling */}
              <CourseContentList>
                {course.active ? (
                  courseDetails[`Course ${course.id}`]?.map((item, index) => (
                    <CourseItem key={index} active={course.active}>
                      <CheckCircleIcon />
                      {item}
                    </CourseItem>
                  ))
                ) : (
                  // Placeholder logic from original
                  <PlaceholderContentOriginal>
                    {courseDetails[`Course ${course.id}`]?.[1] || 'Курс пока недоступен'}
                  </PlaceholderContentOriginal>
                )}
              </CourseContentList>

              {/* Income Container using original data logic, original styling */}
              <IncomeContainerOriginal>
                <IncomeTitleOriginal>Ожидаемый доход: </IncomeTitleOriginal>
                <IncomeOriginal>
                  {course.id === 1 ? "800–2000$" : "1000$ - "}
                  <InfinitySymbolOriginal>∞</InfinitySymbolOriginal>
                </IncomeOriginal>
              </IncomeContainerOriginal>

              {/* Discount Notice using original data logic, original styling */}
              {course.active && course.discount_percentage > 0 && course.discount_end_date && (
                <DiscountNoticeOriginal>
                  Успей купить со скидкой до {new Date(course.discount_end_date).toLocaleDateString('ru-RU')}
                </DiscountNoticeOriginal>
              )}

              {/* Price & Action container using original structure/components */}
              <PriceContainerOriginal>
                {/* Price display from original */}
                {course.active && (
                  <>
                    <PriceOriginal>
                      ЦЕНА: {course.discounted_price_usd}$
                      {course.discount_percentage > 0 && <OldPriceOriginal>{course.price_usd}$</OldPriceOriginal>}
                    </PriceOriginal>
                    <PriceOriginal>
                      ЦЕНА: {course.discounted_price_rub}₽
                      {course.discount_percentage > 0 && <OldPriceOriginal>{course.price_rub}₽</OldPriceOriginal>}
                    </PriceOriginal>
                  </>
                )}
                {/* Buttons using original structure/components, triggering new modal logic */}
                <ButtonContainerOriginal>
                  {course.active ? (
                    <>
                      <BuyButtonOriginal onClick={() => handleOpenModal(course, 'purchase', 'usd')}>Купить за $</BuyButtonOriginal>
                      <BuyButtonOriginal onClick={() => handleOpenModal(course, 'purchase', 'rub')}>Купить за ₽</BuyButtonOriginal>
                    </>
                  ) : (
                    <NotifyButtonOriginal onClick={() => handleOpenModal(course, 'notify')}>Уведомить меня</NotifyButtonOriginal>
                  )}
                </ButtonContainerOriginal>
                {/* Notify text from original */}
                {!course.active && (
                  <NotifyTextOriginal>Подпишитесь, чтобы узнать, когда курс станет доступным.</NotifyTextOriginal>
                )}
              </PriceContainerOriginal>
            </CourseCard>
          ))}
      </CoursesContainer>

      {/* Modals using refined components and logic */}
      {modalType && selectedCourse && (
        <Elements stripe={stripePromise}>
          {modalType === 'purchase' ? (
            <PurchaseModal course={selectedCourse} onClose={handleCloseModal} sourceTag={sourceTag} />
          ) : (
            <NotifyModal course={selectedCourse} onClose={handleCloseModal} email={emailForNotify} setEmail={setEmailForNotify} handleSubmitEmail={handleSubmitEmail} />
          )}
        </Elements>
      )}
    </Section>
  );
};


// --- Purchase Modal (Keep the refined component from previous step) ---
// interface PurchaseModalProps { ... } // Keep interface
// const PurchaseModal: React.FC<PurchaseModalProps> = ({ course, onClose, sourceTag }) => { ... }; // Keep entire refined modal component
interface PurchaseModalProps {
  course: Course;
  onClose: () => void;
  sourceTag: string | null;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({ course, onClose, sourceTag }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [installmentPlan, setInstallmentPlan] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [promoValidation, setPromoValidation] = useState<{ valid: boolean; discount_percentage: number; discounted_price: number } | null>(null);
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoError, setPromoError] = useState<string | null>(null);

  const handleApplyPromo = async () => {
    if (!promoCode.trim()) {
      setPromoError('Введите промокод.');
      return;
    }
    setPromoLoading(true);
    setPromoError(null);
    try {
      const currencyForPromo = course.currency;
      if (!currencyForPromo) {
        setPromoError('Валюта не выбрана.');
        setPromoLoading(false);
        return;
      }
      const { data } = await axios.post('/api/validate_promo', {
        promo_code: promoCode,
        course_id: course.id,
        currency: currencyForPromo
      });
      if (data.valid) {
        setPromoValidation(data);
      } else {
        setPromoError(data.message || 'Промокод недействителен.');
        setPromoValidation(null);
      }
    } catch (err: any) {
      console.error("Promo validation error:", err);
      setPromoError(err.response?.data?.message || 'Ошибка проверки промокода.');
      setPromoValidation(null);
    } finally {
      setPromoLoading(false);
    }
  };

  const handlePayment = async (paymentMethodId: string) => {
    if (!stripe || !course.currency) {
      setError('Ошибка инициализации платежной системы.');
      setLoading(false);
      return;
    }
    try {
      const { data } = await axios.post('/api/purchase', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        course_id: course.id,
        currency: course.currency,
        installment_plan: installmentPlan || null,
        paymentMethodId,
        source_tag: sourceTag,
        promo_code: promoValidation?.valid ? promoCode : null
      });

      if (course.currency === 'usd' && data.client_secret) {
        const client_secret = data.client_secret;
        let stripeAction;
        if (installmentPlan) {
          stripeAction = await stripe.confirmCardSetup(client_secret);
          if (stripeAction.error) throw stripeAction.error;
          if (stripeAction.setupIntent?.status !== 'succeeded') {
            throw new Error('Статус подтверждения рассрочки: ' + stripeAction.setupIntent?.status);
          }
        } else {
          stripeAction = await stripe.confirmCardPayment(client_secret);
          if (stripeAction.error) throw stripeAction.error;
          if (stripeAction.paymentIntent?.status !== 'succeeded') {
            throw new Error('Статус платежа: ' + stripeAction.paymentIntent?.status);
          }
        }
        window.location.href = '/payment-success';
        onClose();
      } else {
        // This case likely implies an issue if USD was expected but no client_secret received
        setError('Неожиданный ответ от сервера для USD платежа.');
      }
    } catch (err: any) {
      console.error('Payment handling error:', err);
      setError(err.message || 'Произошла ошибка при обработке платежа.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!firstName || !lastName || !email || !phone) {
      setError('Пожалуйста, заполните все контактные данные.');
      setLoading(false);
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Пожалуйста, введите корректный email.');
      setLoading(false);
      return;
    }

    if (course.currency === 'usd') {
      if (!stripe || !elements) {
        setError('Платежная система не загружена.'); setLoading(false); return;
      }
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setError('Компонент карты не найден.'); setLoading(false); return;
      }
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card', card: cardElement,
        billing_details: { name: `${firstName} ${lastName}`, email: email, phone: phone, }
      });
      if (paymentMethodError) {
        setError(paymentMethodError.message || 'Ошибка создания платежного метода.'); setLoading(false); return;
      }
      if (paymentMethod) await handlePayment(paymentMethod.id);

    } else if (course.currency === 'rub') {
      try {
        const { data } = await axios.post('/api/yookassa/create-payment', { // Ensure this endpoint exists
          first_name: firstName, last_name: lastName, email: email, phone: phone,
          course_id: course.id, installment_plan: installmentPlan || null, source_tag: sourceTag,
          promo_code: promoValidation?.valid ? promoCode : null
        });
        if (data.confirmation?.confirmation_url) {
          window.location.href = data.confirmation.confirmation_url; // Redirect to YooKassa
        } else {
          setError('Не удалось получить ссылку на оплату от ЮKassa.');
        }
      } catch (err: any) {
        console.error("YooKassa error:", err);
        setError(err.response?.data?.message || 'Ошибка при создании платежа через ЮKassa.');
      } finally { setLoading(false); }
    } else { setError('Выбрана неверная валюта.'); setLoading(false); }
  };

  // Calculations remain the same
  const currencySymbol = course.currency === 'usd' ? '$' : '₽';
  const basePrice = course.currency === 'usd' ? course.price_usd : course.price_rub;
  const discountedPrice = course.currency === 'usd' ? course.discounted_price_usd : course.discounted_price_rub;
  const finalPrice = promoValidation?.valid ? promoValidation.discounted_price : discountedPrice;
  const originalPriceToShow = promoValidation?.valid ? discountedPrice : (course.discount_percentage > 0 ? basePrice : 0);

  const installmentDetails = (plan: string) => {
    const totalInstallments = parseInt(plan);
    if (!totalInstallments || totalInstallments <= 0) return '';
    const installmentAmount = (finalPrice / totalInstallments).toFixed(2);
    return ` (${totalInstallments} × ${installmentAmount} ${currencySymbol})`;
  };

  // Using refined modal structure and styles
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContentStyled onClick={(e) => e.stopPropagation()}>
        <ModalCloseButton onClick={onClose}>×</ModalCloseButton>
        <ModalTitleStyled>Оформление доступа к курсу "{course.name}"</ModalTitleStyled>
        <CourseRecap>
          Оплата в: <strong style={{ color: '#ff9900' }}>{course.currency?.toUpperCase()}</strong>
        </CourseRecap>

        <FormStyled onSubmit={handleSubmit}>
          <SectionTitleModal>1. Контактная информация</SectionTitleModal>
          <InputGrid>
            <InputFieldStyled>
              <label htmlFor="firstName">Имя:</label>
              <input id="firstName" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            </InputFieldStyled>
            <InputFieldStyled>
              <label htmlFor="lastName">Фамилия:</label>
              <input id="lastName" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </InputFieldStyled>
          </InputGrid>
          <InputFieldStyled>
            <label htmlFor="email">Email:</label>
            <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </InputFieldStyled>
          <InputFieldStyled>
            <label htmlFor="phone">Телефон:</label>
            <input id="phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+7XXXXXXXXXX" required />
          </InputFieldStyled>

          <SectionTitleModal>2. Промокод и рассрочка</SectionTitleModal>
          <InputFieldStyled>
            <label htmlFor="promoCode">Промокод:</label>
            <PromoContainer>
              <input id="promoCode" type="text" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} placeholder="Есть промокод?" disabled={promoLoading} />
              <PromoButton type="button" onClick={handleApplyPromo} disabled={promoLoading || !promoCode.trim()}> {promoLoading ? '...' : 'Применить'} </PromoButton>
            </PromoContainer>
            {promoError && <ErrorTextModal>{promoError}</ErrorTextModal>}
            {promoValidation?.valid && (<PromoSuccessText> Промокод применен! Скидка {promoValidation.discount_percentage}%. </PromoSuccessText>)}
          </InputFieldStyled>
          <InputFieldStyled>
            <label htmlFor="installmentPlan">План оплаты:</label>
            <SelectStyled id="installmentPlan" value={installmentPlan} onChange={(e) => setInstallmentPlan(e.target.value)}>
              <option value="">Полная оплата</option>
              <option value="2">Рассрочка на 2 недели</option>
              <option value="4">Рассрочка на 4 недели</option>
              <option value="8">Рассрочка на 8 недель</option>
            </SelectStyled>
          </InputFieldStyled>

          <SectionTitleModal>3. Оплата</SectionTitleModal>
          <PriceSummary>
            {originalPriceToShow > 0 && (
              <PriceItem>
                <span>Стандартная цена:</span>
                <CrossedOutPrice>{originalPriceToShow.toFixed(2)} {currencySymbol}</CrossedOutPrice>
              </PriceItem>
            )}
            {promoValidation?.valid && (
              <PriceItem>
                <span>Скидка по промокоду:</span>
                <span>- {promoValidation.discount_percentage}%</span>
              </PriceItem>
            )}
            <PriceItem total>
              <span>{installmentPlan ? `К оплате ${installmentDetails(installmentPlan)}:` : 'Итого к оплате:'}</span>
              <span>{finalPrice.toFixed(2)} {currencySymbol}</span>
            </PriceItem>
          </PriceSummary>

          {course.currency === 'usd' && (
            <>
              <CardElementWrapperStyled> <CardElement options={{ style: cardElementStyle }} /> </CardElementWrapperStyled>
              <PaymentMethodNoticeStyled> 🔒 Безопасная оплата картами Visa, MasterCard, Discover, Amex через Stripe. </PaymentMethodNoticeStyled>
            </>
          )}
          {course.currency === 'rub' && (<PaymentMethodNoticeStyled> 🇷🇺 Оплата российскими картами через ЮKassa. Вы будете перенаправлены на страницу оплаты. </PaymentMethodNoticeStyled>)}
          {installmentPlan && (<InstallmentNoticeStyled> ℹ️ Выбрав рассрочку, вы соглашаетесь на автоматические еженедельные списания. Доступ к курсу предоставляется сразу после первого платежа. </InstallmentNoticeStyled>)}

          {error && <ErrorTextModal>{error}</ErrorTextModal>}
          <FinalCtaButton type="submit" disabled={loading}>
            {loading ? 'Обработка...' : (installmentPlan ? `Оплатить ${(finalPrice / parseInt(installmentPlan)).toFixed(2)} ${currencySymbol} и начать` : `Оплатить ${finalPrice.toFixed(2)} ${currencySymbol} и начать`)}
          </FinalCtaButton>
        </FormStyled>
        <TermsNoticeStyled>
          Нажимая кнопку оплаты, вы соглашаетесь с{' '} <a href="https://amazonfreedom.ru/terms" target="_blank" rel="noopener noreferrer"> условиями оферты </a>.
        </TermsNoticeStyled>
      </ModalContentStyled>
    </ModalOverlay>
  );
};


// --- Notify Modal (Keep the refined component from previous step) ---
// interface NotifyModalProps { ... } // Keep interface
// const NotifyModal: React.FC<NotifyModalProps> = ({ ... }) => { ... }; // Keep entire refined modal component
interface NotifyModalProps {
  course: Course;
  onClose: () => void;
  email: string;
  setEmail: (email: string) => void;
  handleSubmitEmail: () => void;
}

const NotifyModal: React.FC<NotifyModalProps> = ({ course, onClose, email, setEmail, handleSubmitEmail }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContentStyled notify onClick={(e) => e.stopPropagation()}>
        <ModalCloseButton onClick={onClose}>×</ModalCloseButton>
        <ModalTitleStyled>Курс "{course.name}" скоро стартует!</ModalTitleStyled>
        <ModalDescriptionStyled>
          Этот курс сейчас готовится к запуску. Оставьте свой email, чтобы первым узнать о старте продаж и получить <HighlightText>специальное предложение</HighlightText> для ранних пташек!
        </ModalDescriptionStyled>

        <FormStyled onSubmit={(e) => { e.preventDefault(); handleSubmitEmail(); }}>
          <InputFieldStyled>
            <label htmlFor="notifyEmail">Ваш лучший email:</label>
            <input id="notifyEmail" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="you@example.com" required />
          </InputFieldStyled>
          <FinalCtaButton type="submit">Хочу узнать первым!</FinalCtaButton>
        </FormStyled>
        <TermsNoticeStyled small>
          Мы не будем спамить. Только важная информация о старте курса.
        </TermsNoticeStyled>
      </ModalContentStyled>
    </ModalOverlay>
  );
};


// --- Styled Components ---

// Base Section Styles (Refined Version)
const Section = styled.section`
  padding: 80px 20px;
  background-color: #f8f9fa;
  @media (max-width: 991px) { padding: 60px 15px; }
`;

const SectionTitle = styled.h2`
  text-align: center; font: 700 54px/1.1 'Bebas Neue', sans-serif; color: #0E0B2C; margin-bottom: 15px; letter-spacing: 1px;
  @media (max-width: 991px) { font-size: 44px; }
  @media (max-width: 767px) { font-size: 36px; }
`;

const HighlightText = styled.span` color: #ff9900; `;

const Subtitle = styled.p`
  text-align: center; font: 400 18px/1.6 'Jost', sans-serif; color: #555; margin: 0 auto 60px auto; max-width: 700px;
  @media (max-width: 991px) { font-size: 16px; margin-bottom: 40px; }
`;

const LoadingText = styled.p` text-align: center; font-size: 18px; color: #555; padding: 40px; `;

const ErrorTextStyled = styled.p` // Renamed from original ErrorText
  text-align: center; font-size: 18px; color: #d93025; padding: 40px; background-color: #f8d7da; border: 1px solid #f5c6cb; border-radius: 8px;
`;

const CoursesContainer = styled.div` display: flex; flex-wrap: wrap; justify-content: center; gap: 35px; align-items: stretch; @media (max-width: 991px) { gap: 25px; } `;

// Card Styling (Refined Version Base)
const CourseCard = styled.div<{ active?: boolean; featured?: boolean }>`
  background: #ffffff; border: 1px solid #e0e0e0; border-radius: 18px; overflow: hidden; position: relative;
  width: clamp(300px, calc(33.33% - 24px), 380px); padding: 25px; /* Equal padding */ color: #333;
  display: flex; flex-direction: column; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

  ${({ featured }) => featured && css`
    border: 2px solid #ff9900; box-shadow: 0 8px 25px rgba(255, 153, 0, 0.15); transform: scale(1.03);
    @media (max-width: 991px) { transform: scale(1.01); }
  `}

   ${({ active }) => !active && css`
    background-color: #f8f9fa; opacity: 0.75;
  `}

  &:hover {
      transform: ${({ featured }) => featured ? 'scale(1.04)' : 'translateY(-6px)'};
      box-shadow: 0 10px 30px rgba(0,0,0, 0.1);
  }

  @media (max-width: 991px) {
      width: clamp(300px, calc(50% - 15px), 450px); transform: none;
      &:hover { transform: translateY(-6px); }
  }
  @media (max-width: 700px) { width: 100%; max-width: 400px; }
`;

// --- Styled Components for Original Information Display (Adapted) ---

// Badge copied from refined version
const FeaturedBadge = styled.div` position: absolute; top: 15px; left: -1px; background-color: #ff9900; color: #fff; padding: 5px 15px 5px 20px; font-size: 13px; font-weight: 600; font-family: 'Jost', sans-serif; border-radius: 0 20px 20px 0; z-index: 2; box-shadow: 2px 2px 5px rgba(0,0,0,0.1); `;

// Original Discount Badge Styling (adapted slightly)
const DiscountBadgeOriginal = styled.div`
  position: absolute;
  top: 15px; right: 15px; background-color: #f90; // Original color
  border-radius: 50%; /* Original shape */
  color: #fff; display: flex; align-items: center; justify-content: center;
  width: 55px; height: 55px; /* Slightly adapted size */
  font: 700 16px/1 'Jost', sans-serif; /* Adapted font */
  z-index: 2; box-shadow: 0 2px 5px rgba(0,0,0,0.2);
`;

// Original Header Structure/Styles (adapted)
const CourseHeaderOriginal = styled.div` display: flex; gap: 20px; margin-bottom: 15px; `;
const ColumnOriginal = styled.div` display: flex; flex-direction: column; width: 100%; /* Takes full width now */ `;
const CourseTitleOriginal = styled.div`
  font: 700 22px/1.2 'Jost', sans-serif; /* Adapted font/size */
  color: #001b4f; /* Dark blue to match new style */
  margin-bottom: 5px;
`;
const CourseSubtitleOriginal = styled.div`
  font: 400 14px/1.4 'Jost', sans-serif; /* Adapted line height */
  color: #555; /* Darker text on white */
  min-height: 36px; /* Ensure space */
`;

// Content Title from refined
const CourseContentTitle = styled.div` font: 600 16px/1 'Jost', sans-serif; color: #333; margin: 15px 0 10px 0; padding-top: 15px; border-top: 1px solid #eee; `;

// Content List from refined structure
const CourseContentList = styled.ul` padding: 0; margin: 0 0 20px 0; list-style: none; flex-grow: 1; min-height: 180px; /* Ensure min height like original */ `;

// Course Item using refined style
const CourseItem = styled.li<{ active?: boolean }>`
  margin-bottom: 8px; line-height: 1.5; font: 400 14px 'Jost', sans-serif; color: ${({ active }) => active ? '#333' : '#777'}; display: flex; align-items: flex-start;
  /* Original bullet style adapted */
  &:before {
      content: '•'; /* Keep original bullet */
      color: #ff9900; /* Original color */
      font-weight: bold;
      display: inline-block;
      width: 15px; /* Space for bullet */
      margin-left: -15px; /* Pull into padding */
      flex-shrink: 0;
  }
  padding-left: 15px; /* Add padding for the bullet */

  /* Hide check icon as original used bullet */
  span { display: none; }
`;

// Placeholder from original (adapted slightly)
const PlaceholderContentOriginal = styled.div`
  color: #888; /* Lighter gray on white */
  font: 400 14px/1.5 'Jost', sans-serif;
  padding: 10px 0;
  text-align: center;
`;

// Income Container from original (adapted)
const IncomeContainerOriginal = styled.div`
  align-items: center; border: 1px solid #004ee4; /* Blue border */ border-radius: 80px;
  display: flex; justify-content: space-between; padding: 8px 15px; margin: 15px 0;
  background-color: #e9f4ff; /* Light blue bg like refined */
`;
const IncomeTitleOriginal = styled.div` font: 600 15px/1 'Jost', sans-serif; color: #003a9e; `;
const IncomeOriginal = styled.div` font: 600 15px/1 'Jost', sans-serif; color: #003a9e; display: flex; align-items: center; `;
const InfinitySymbolOriginal = styled.span` font-size: 28px; vertical-align: middle; font-weight: 300; margin-left: 3px; line-height: 1; `;

// Discount Notice from original (adapted)
const DiscountNoticeOriginal = styled.div`
  text-align: center; margin: 15px 0 5px 0; font: 400 14px/1.2 'Jost', sans-serif; color: #f90; font-weight: 500;
`;

// Price Container & Items from original (adapted)
const PriceContainerOriginal = styled.div`
  margin-top: auto; /* Pushes to bottom */ padding-top: 15px; border-top: 1px solid #eee;
  display: flex; flex-direction: column; align-items: center; gap: 8px; /* Gap between prices/buttons */
`;
const PriceOriginal = styled.div` font: 600 18px/1 'Jost', sans-serif; color: #333; /* Dark text on white */ `;
const OldPriceOriginal = styled.span` text-decoration: line-through; margin-left: 8px; color: #a0a0a0; font-weight: 400; font-size: 16px; `;

// Buttons from original (adapted styles)
const ButtonContainerOriginal = styled.div` display: flex; gap: 10px; margin-top: 10px; width: 100%; `;
// BuyButton using original style adapted
const BuyButtonOriginal = styled.button`
  flex: 1; /* Make buttons share width */ align-items: center; background: #ff9900; border-radius: 140px; color: #ffffff;
  display: flex; font: 500 15px/1 'Jost', sans-serif; padding: 10px 15px; cursor: pointer; justify-content: center; border: none; white-space: nowrap;
  transition: background-color 0.2s ease, transform 0.2s ease;
  &:hover { background-color: #e08900; transform: scale(1.03); }
`;
// NotifyButton using original style adapted
const NotifyButtonOriginal = styled.button`
  width: 100%; align-items: center; background: #004ee4; /* Blue for notify */ border-radius: 140px; color: #ffffff;
  display: flex; font: 500 15px/1 'Jost', sans-serif; padding: 10px 20px; cursor: pointer; justify-content: center; border: none;
  transition: background-color 0.2s ease, transform 0.2s ease;
  &:hover { background-color: #003a9e; transform: scale(1.03); }
`;
const NotifyTextOriginal = styled.div` font: 400 13px/1.3 'Jost', sans-serif; color: #777; margin-top: 8px; text-align: center; `;


// --- Modal Styles (Keep refined styles from previous step) ---
// (Keep all ModalOverlay, ModalContentStyled, ModalCloseButton, ModalTitleStyled, etc. styles from the refined version)
const ModalOverlay = styled.div` position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(14, 11, 44, 0.8); backdrop-filter: blur(5px); display: flex; justify-content: center; align-items: center; z-index: 1100; padding: 20px; overflow-y: auto; `;
const ModalContentStyled = styled.div<{ notify?: boolean }>`
  background: #ffffff; color: #333; padding: 30px 35px; border-radius: 16px; width: 100%; max-width: ${({ notify }) => notify ? '500px' : '650px'}; position: relative; box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2); max-height: 90vh; overflow-y: auto; animation: fadeInModal 0.3s ease-out;
  @keyframes fadeInModal { from { opacity: 0; transform: scale(0.95); } to { opacity: 1; transform: scale(1); } }
  @media (max-width: 767px) { padding: 25px 20px; max-height: 85vh; }
`;
const ModalCloseButton = styled.button` position: absolute; top: 15px; right: 15px; font-size: 28px; line-height: 1; color: #aaa; background: none; border: none; cursor: pointer; padding: 5px; transition: color 0.2s ease; &:hover { color: #333; } `;
const ModalTitleStyled = styled.h3` font: 700 26px/1.2 'Bebas Neue', sans-serif; color: #001b4f; margin: 0 0 25px 0; text-align: center; padding-right: 30px; @media (max-width: 767px) { font-size: 22px; margin-bottom: 20px; } `;
const ModalDescriptionStyled = styled.p` font: 400 16px/1.6 'Jost', sans-serif; color: #555; text-align: center; margin: 0 auto 25px auto; max-width: 90%; `;
const FormStyled = styled.form` display: flex; flex-direction: column; gap: 18px; `;
const InputFieldStyled = styled.div`
  display: flex; flex-direction: column; gap: 6px;
  label { font-size: 14px; font-weight: 500; color: #444; }
  input, select { width: 100%; padding: 12px 15px; font-size: 16px; font-family: 'Jost', sans-serif; border: 1px solid #ccc; border-radius: 6px; background-color: #fff; transition: border-color 0.2s ease, box-shadow 0.2s ease; &:focus { outline: none; border-color: #004ee4; box-shadow: 0 0 0 3px rgba(0, 78, 228, 0.1); } &::placeholder { color: #aaa; } }
`;
const SelectStyled = styled.select` appearance: none; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'%3E%3Cpath fill='%23888' d='M6 8L0 2l1.4-1.4L6 5.2 10.6.6 12 2z'/%3E%3C/svg%3E"); background-repeat: no-repeat; background-position: right 15px center; background-size: 12px; padding-right: 40px; `;
const InputGrid = styled.div` display: grid; grid-template-columns: 1fr 1fr; gap: 15px; @media (max-width: 500px) { grid-template-columns: 1fr; } `;
const PromoContainer = styled.div` display: flex; gap: 10px; `;
const PromoButton = styled.button` padding: 10px 15px; font-size: 14px; font-weight: 500; background-color: #e9ecef; color: #495057; border: 1px solid #ced4da; border-radius: 6px; cursor: pointer; transition: background-color 0.2s ease; white-space: nowrap; &:hover { background-color: #dee2e6; } &:disabled { opacity: 0.6; cursor: not-allowed; } `;
const PromoSuccessText = styled.div` margin-top: 8px; color: #28a745; font-size: 14px; font-weight: 500; `;
const ErrorTextModal = styled.p` color: #d93025; font-size: 14px; margin: 5px 0 0 0; text-align: center; `;
// Final CTA Button from refined
const CtaButton = styled.button<{ primary?: boolean; secondary?: boolean }>`
  width: 100%; padding: 12px 20px; border: none; border-radius: 8px; font: 600 16px 'Jost', sans-serif; cursor: pointer; transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease; text-align: center; white-space: nowrap;
  ${({ primary }) => primary && css` background-color: #ff9900; color: #fff; box-shadow: 0 4px 10px rgba(255, 153, 0, 0.3); &:hover { background-color: #e08900; transform: scale(1.03); box-shadow: 0 6px 15px rgba(255, 153, 0, 0.4); } `}
  ${({ secondary }) => secondary && css` background-color: transparent; color: #004ee4; border: 2px solid #004ee4; &:hover { background-color: rgba(0, 78, 228, 0.05); transform: scale(1.03); } `}
  ${({ primary, secondary }) => !(primary || secondary) && css` background-color: #004ee4; color: #fff; box-shadow: 0 4px 10px rgba(0, 78, 228, 0.2); &:hover { background-color: #003a9e; transform: scale(1.03); box-shadow: 0 6px 15px rgba(0, 78, 228, 0.3); } `}
  &:disabled { background-color: #b0b0b0 !important; cursor: not-allowed; box-shadow: none; transform: none; }
  &:active { transform: scale(0.98); }
`;
const FinalCtaButton = styled(CtaButton)` margin-top: 15px; width: 100%; background-color: #ff9900; color: #fff; box-shadow: 0 4px 10px rgba(255, 153, 0, 0.3); &:hover { background-color: #e08900; transform: scale(1.03); box-shadow: 0 6px 15px rgba(255, 153, 0, 0.4); } &:disabled { background-color: #b0b0b0 !important; box-shadow: none; transform: none; } `;
const CardElementWrapperStyled = styled.div` margin: 10px 0; padding: 15px; background-color: #f8f9fa; border: 1px solid #dee2e6; border-radius: 6px; `;
const cardElementStyle = { base: { color: '#32325d', fontFamily: '"Jost", sans-serif', fontSmoothing: 'antialiased', fontSize: '16px', '::placeholder': { color: '#aab7c4', }, }, invalid: { color: '#fa755a', iconColor: '#fa755a', }, };
const CourseRecap = styled.div` text-align: center; margin-bottom: 20px; font-size: 15px; color: #555; background-color: #e9f4ff; padding: 8px; border-radius: 6px; `;
const SectionTitleModal = styled.h4` font-size: 16px; font-weight: 600; color: #004ee4; margin: 20px 0 10px 0; padding-bottom: 5px; border-bottom: 1px solid #e0e0e0; `;
const PriceSummary = styled.div` background-color: #f8f9fa; border-radius: 8px; padding: 15px 20px; margin: 10px 0; border: 1px solid #eee; `;
const PriceItem = styled.div<{ total?: boolean }>` display: flex; justify-content: space-between; align-items: center; font-size: ${({ total }) => total ? '18px' : '15px'}; color: ${({ total }) => total ? '#0E0B2C' : '#555'}; font-weight: ${({ total }) => total ? '700' : '400'}; margin-bottom: ${({ total }) => total ? '0' : '8px'}; span:last-child { font-weight: ${({ total }) => total ? '700' : '500'}; } `;
const CrossedOutPrice = styled.span` text-decoration: line-through; color: #999; `;
const PaymentMethodNoticeStyled = styled.div` font-size: 13px; color: #555; margin: 15px 0 5px 0; text-align: center; background-color: #f8f9fa; padding: 8px 12px; border-radius: 6px; border: 1px solid #eee; `;
const InstallmentNoticeStyled = styled(PaymentMethodNoticeStyled)` color: #004ee4; background-color: #e9f4ff; border-color: #cce2ff; `;
const TermsNoticeStyled = styled.div<{ small?: boolean }>` font-size: ${({ small }) => small ? '12px' : '13px'}; color: #777; margin-top: 25px; text-align: center; line-height: 1.5; a { color: #004ee4; text-decoration: underline; &:hover { text-decoration: none; color: #ff9900; } } `;


export default CourseSection;