import React, { useState } from 'react'; // Import useState
import styled, { keyframes } from 'styled-components';

// --- Asset Imports ---
import Image1 from '../assets/reviews/1.png';
import Image2 from '../assets/reviews/2.png';
import Julia from '../assets/reviews/julia.jpg';
import Video3 from '../assets/reviews/3.mp4';

// --- Types ---
interface Review {
  id: number;
  name: string;
  reviewerPhotoUrl: string;
  reviewImageUrl?: string;
  videoUrl?: string;
  sourceLink?: string;
  text?: string;
}

// --- Sample Data ---
const reviewsData: Review[] = [
  {
    id: 1,
    name: 'Valeria',
    reviewerPhotoUrl: '',
    reviewImageUrl: Image1,
    sourceLink: 'https://t.me/juliaintheusachat2/2635',
  },
  {
    id: 2,
    name: 'Artur',
    reviewerPhotoUrl: '',
    reviewImageUrl: Image2,
    sourceLink: 'https://t.me/juliaintheusachat2/2641',
  },
  {
    id: 3,
    name: 'Результаты Юлии (Автора)',
    reviewerPhotoUrl: Julia,
    videoUrl: Video3,
    sourceLink: 'https://t.me/juliaintheusachat/435',
    text: 'Взгляните на реальные результаты работы на Amazon за прошлый год!',
  },
  // Add more reviews as needed
];

// --- Animations ---
const fadeInModal = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const scaleUpModal = keyframes`
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
`;


// --- Component ---
const ReviewsSection: React.FC = () => {
  // --- State for Modal ---
  const [openedImageSrc, setOpenedImageSrc] = useState<string | null>(null);

  const openModal = (src: string) => {
    setOpenedImageSrc(src);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  const closeModal = () => {
    setOpenedImageSrc(null);
    document.body.style.overflow = ''; // Restore background scrolling
  };

  // --- Event Listener for Esc Key (Optional but good UX) ---
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && openedImageSrc) {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      // Cleanup overflow style if component unmounts while modal is open
      if (openedImageSrc) {
        document.body.style.overflow = '';
      }
    };
  }, [openedImageSrc]); // Re-run effect if openedImageSrc changes


  return (
    <SectionWrapperReview id="reviews">
      <ContainerReview>
        <HeaderReview>
          <TitleReview>ИСТОРИИ УСПЕХА НАШИХ <YellowTextReview>УЧЕНИКОВ</YellowTextReview></TitleReview>
          <DescriptionReview>Не верьте нам на слово – посмотрите, что говорят те, кто уже прошел обучение, и реальные результаты.</DescriptionReview>
        </HeaderReview>
        <ReviewsGridReview>
          {reviewsData.map((review) => (
            <ReviewCardReview key={review.id}>
              <CardHeaderReview>
                {review.reviewerPhotoUrl ? (
                  <ReviewerPhotoReview src={review.reviewerPhotoUrl} alt={`Фото ${review.name}`} loading="lazy" />
                ) : (
                  <PlaceholderPhotoReview>{review.name.substring(0, 1)}</PlaceholderPhotoReview>
                )}
                <ReviewerNameReview>{review.name}</ReviewerNameReview>
              </CardHeaderReview>

              {/* --- Image with onClick handler --- */}
              {review.reviewImageUrl && (
                <ReviewImageWrapper onClick={() => openModal(review.reviewImageUrl!)}> {/* Added onClick */}
                  <ReviewImageReview src={review.reviewImageUrl} alt={`Отзыв от ${review.name}`} loading="lazy" />
                </ReviewImageWrapper>
              )}

              {review.videoUrl && (
                <VideoWrapperReview>
                  <ReviewVideoReview controls preload="metadata">
                    <source src={review.videoUrl} type="video/mp4" />
                    Ваш браузер не поддерживает воспроизведение видео.
                  </ReviewVideoReview>
                </VideoWrapperReview>
              )}

              {review.text && <ReviewTextReview>"{review.text}"</ReviewTextReview>}

              {review.sourceLink && (
                <SourceLinkWrapper>
                  <SourceLinkReview href={review.sourceLink} target="_blank" rel="noopener noreferrer">
                    Смотреть оригинал ↗
                  </SourceLinkReview>
                </SourceLinkWrapper>
              )}
            </ReviewCardReview>
          ))}
        </ReviewsGridReview>
      </ContainerReview>

      {/* --- Modal Rendering --- */}
      {openedImageSrc && (
        <ModalOverlay onClick={closeModal}> {/* Close on overlay click */}
          {/* Stop propagation prevents closing modal when clicking inside the content */}
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>×</CloseButton>
            <ModalImage src={openedImageSrc} alt="Увеличенный отзыв" />
          </ModalContent>
        </ModalOverlay>
      )}
    </SectionWrapperReview>
  );
};


// --- Styles ---
const SectionWrapperReview = styled.section`
  padding: 80px 20px;
  background-color: #f8f9fa;
  @media (max-width: 991px) { padding: 60px 15px; }
`;

const ContainerReview = styled.div`
  max-width: 1240px; margin: 0 auto; display: flex; flex-direction: column; align-items: center;
`;

const HeaderReview = styled.div`
  text-align: center; margin-bottom: 60px; max-width: 800px;
  @media (max-width: 991px) { margin-bottom: 45px; }
`;

const TitleReview = styled.h2`
  font: 700 54px/1.1 'Bebas Neue', sans-serif;
  margin: 0 0 15px 0; color: #0E0B2C; letter-spacing: 1px;
  @media (max-width: 991px) { font-size: 44px; }
  @media (max-width: 767px) { font-size: 36px; }
`;

const YellowTextReview = styled.span` color: #FF9900; `;

const DescriptionReview = styled.p`
  font: 400 18px/1.6 'Jost', sans-serif; color: #555; margin: 0;
  @media (max-width: 991px) { font-size: 16px; }
`;

const ReviewsGridReview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  width: 100%;
  @media (max-width: 767px) {
      gap: 25px;
      grid-template-columns: 1fr;
   }
`;

const ReviewCardReview = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
      transform: translateY(-6px);
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  }
`;

const CardHeaderReview = styled.div`
    display: flex; align-items: center; gap: 12px;
    padding: 15px 20px;
`;

const ReviewerPhotoReview = styled.img`
    width: 50px; height: 50px;
    border-radius: 50%; object-fit: cover; border: 2px solid #fff;
    box-shadow: 0 1px 4px rgba(0,0,0,0.1); flex-shrink: 0;
    background-color: #eee;
`;

const PlaceholderPhotoReview = styled.div`
    width: 50px; height: 50px;
    border-radius: 50%;
    background-color: #004ee4;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Jost', sans-serif;
    flex-shrink: 0;
`;


const ReviewerNameReview = styled.h4`
    font-family: 'Jost', sans-serif; font-size: 17px; font-weight: 600; color: #0E0B2C;
    margin: 0; line-height: 1.3;
`;

// --- Image Wrapper now clickable ---
const ReviewImageWrapper = styled.div`
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    line-height: 0;
    cursor: pointer; // Indicate it's clickable
`;

const ReviewImageReview = styled.img`
    display: block;
    width: 100%; height: auto;
    max-height: 400px;
    object-fit: contain;
    background-color: #e9ecef;
`;

const VideoWrapperReview = styled.div`
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    line-height: 0;
    background-color: #000;
`;

const ReviewVideoReview = styled.video`
    display: block;
    width: 100%;
    height: auto;
    max-height: 400px;
    border: none;
`;

const ReviewTextReview = styled.p`
    font-family: 'Jost', sans-serif; font-size: 15px; line-height: 1.6; color: #333;
    margin: 0; padding: 15px 20px;
    font-style: italic;
    flex-grow: 1;
`;

const SourceLinkWrapper = styled.div`
    padding: 15px 20px;
    margin-top: auto;
    text-align: center;
    border-top: 1px solid #e0e0e0;
`;

const SourceLinkReview = styled.a`
    font-family: 'Jost', sans-serif; font-size: 14px; color: #004ee4;
    text-decoration: none; font-weight: 500; transition: color 0.2s ease, background-color 0.2s ease;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;

    &:hover {
        color: #f90;
        background-color: rgba(0, 78, 228, 0.05);
    }
`;

// --- Modal Styles ---
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); // Dark semi-transparent overlay
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Ensure it's above everything else
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer; // Indicate clicking closes it
  animation: ${fadeInModal} 0.3s ease-out forwards;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff; // Optional background for the image container itself
  padding: 10px; // Small padding around image
  border-radius: 8px;
  max-width: 90vw; // Limit width to 90% of viewport width
  max-height: 90vh; // Limit height to 90% of viewport height
  overflow: hidden; // Hide any overflow from the image itself
  cursor: default; // Default cursor for the content area
  animation: ${scaleUpModal} 0.3s ease-out forwards;
  display: flex; // Use flex to potentially center image if needed
`;

const ModalImage = styled.img`
  display: block;
  max-width: 100%; // Image won't exceed modal content width
  max-height: calc(90vh - 20px); // Adjust height considering padding
  object-fit: contain; // Show the whole image, scaled down if needed
  user-select: none; // Prevent image selection
`;

const CloseButton = styled.button`
  position: absolute;
  top: -10px; // Position slightly outside the top-right corner
  right: -10px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  z-index: 1010; // Above modal content
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #eee;
    color: #000;
  }
`;

export default ReviewsSection;