import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios'; // Если используете axios для отправки

// Placeholder image (можете использовать ту же, что и в FreeGuideSection)
const GuideMockup = "https://via.placeholder.com/200x280.png?text=Free+Guide";

// Анимации для модального окна
const fadeInOverlay = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const scaleUpContent = keyframes`
  from { opacity: 0; transform: translate(-50%, -50%) scale(0.9); }
  to { opacity: 1; transform: translate(-50%, -50%) scale(1); }
`;

// Типы пропсов
interface ExitIntentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: () => void; // Опциональный колбэк при успехе, чтобы пометить, что гайд запрошен
}

// --- Компонент модального окна ---
const ExitIntentModal: React.FC<ExitIntentModalProps> = ({ isOpen, onClose, onSuccess }) => {
    const [email, setEmail] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); setError(''); setIsLoading(true);
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setError('Пожалуйста, введите корректный email.'); setIsLoading(false); return;
        }
        try {
            console.log('Submitting email from exit intent:', email);
            // === ВАШ ЗАПРОС НА СЕРВЕР ===
            // await axios.post('/api/subscribe-guide', { email }); // Пример
            await new Promise(resolve => setTimeout(resolve, 1000)); // Имитация запроса
            // ===========================
            setIsSubmitted(true);
            setEmail('');
            // Вызываем колбэк onSuccess, если он передан
            if (onSuccess) {
                onSuccess();
            }
            // Закрываем окно через несколько секунд после успеха
            setTimeout(onClose, 3500); // Закрыть через 3.5 секунды
        } catch (err: any) {
            console.error("Error submitting guide email (exit intent):", err);
            setError(err.response?.data?.message || 'Не удалось отправить запрос. Попробуйте позже.');
        } finally {
            setIsLoading(false);
        }
    };

    // Не рендерим ничего, если окно не открыто
    if (!isOpen) {
        return null;
    }

    return (
        <ModalOverlay onClick={onClose}> {/* Закрытие по клику на оверлей */}
            <ModalContent onClick={(e) => e.stopPropagation()}> {/* Предотвращение закрытия при клике на контент */}
                <CloseButton onClick={onClose}>×</CloseButton>

                <ModalGrid>
                    <ImageColumn>
                        <GuideImageExit src={GuideMockup} alt="Обложка гайда Amazon Quick Start" />
                        <BadgeExit>ПОСЛЕДНИЙ ШАНС!</BadgeExit>
                    </ImageColumn>

                    <FormColumn>
                        <ModalTitle>СТОЙТЕ! Не уходите с пустыми руками!</ModalTitle>
                        <ModalDescription>
                            Получите наш <HighlightTextExit>бесплатный PDF-гайд</HighlightTextExit> "Amazon Quick Start" и сделайте первый шаг к своему бизнесу уже сегодня.
                        </ModalDescription>

                        {isSubmitted ? (
                            <SuccessMessageExit>
                                🚀 Гайд уже летит к вам на почту! Проверьте папку "Входящие" (и "Спам").
                            </SuccessMessageExit>
                        ) : (
                            <FormExit onSubmit={handleSubmit} noValidate>
                                <InputExit
                                    type="email"
                                    placeholder="Введите ваш email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    aria-label="Ваш email для получения гайда"
                                    disabled={isLoading}
                                />
                                {error && <ErrorMessageExit>{error}</ErrorMessageExit>}
                                <SubmitButtonExit type="submit" disabled={isLoading}>
                                    {isLoading ? 'Отправка...' : 'ПОЛУЧИТЬ ГАЙД БЕСПЛАТНО'}
                                </SubmitButtonExit>
                                <PrivacyNoticeExit>
                                    Ваш email в безопасности. Никакого спама.
                                </PrivacyNoticeExit>
                            </FormExit>
                        )}
                    </FormColumn>
                </ModalGrid>

            </ModalContent>
        </ModalOverlay>
    );
};

// --- Стили для модального окна ---
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; // Выше хедера
  padding: 15px;
  box-sizing: border-box;
  animation: ${fadeInOverlay} 0.3s ease-out forwards;
`;

const ModalContent = styled.div`
  position: fixed; // Используем fixed для центрирования относительно viewport
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9); // Начальное состояние для анимации
  width: 100%;
  max-width: 750px; // Ширина модального окна
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden; // Важно для border-radius дочерних элементов
  z-index: 1101;
  animation: ${scaleUpContent} 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards; // 'Back' ease
  opacity: 0; // Начальное состояние для анимации

  @media (max-width: 767px) {
    max-width: 95%;
    top: auto; // Убираем top: 50% на мобильных
    bottom: 20px; // Прижимаем к низу
    left: 50%;
    transform: translateX(-50%) scale(0.9); // Только по X
     // Можно добавить анимацию выезда снизу
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  z-index: 1102;
  transition: background-color 0.2s ease;
  padding: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

   @media (max-width: 767px) {
    top: 8px;
    right: 8px;
    width: 26px;
    height: 26px;
    font-size: 18px;
    line-height: 26px;
   }
`;

const ModalGrid = styled.div`
    display: grid;
    grid-template-columns: 250px 1fr; // Колонка для картинки и колонка для формы
    align-items: center; // Выравнивание по центру вертикально
    gap: 0; // Без отступов между колонками

    @media (max-width: 767px) {
        grid-template-columns: 1fr; // Одна колонка на мобильных
    }
`;

const ImageColumn = styled.div`
    background: linear-gradient(135deg, #f0f4ff 0%, #e6edff 100%); // Легкий фон
    padding: 30px 20px;
    display: flex;
    flex-direction: column; // Для бейджа
    align-items: center;
    justify-content: center;
    position: relative; // Для бейджа
    height: 100%; // Занять всю высоту грид-ячейки

     @media (max-width: 767px) {
        display: none; // Скрываем картинку на мобильных для экономии места
     }
`;

const GuideImageExit = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    filter: drop-shadow(0 5px 15px rgba(0,0,0,0.15));
`;

const BadgeExit = styled.div`
    position: absolute;
    top: 20px;
    left: -8px;
    background-color: #ff4d4f; // Красный цвет для внимания
    color: white;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    transform: rotate(-6deg);
    z-index: 2;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
`;


const FormColumn = styled.div`
    padding: 35px 40px;
    display: flex;
    flex-direction: column;

     @media (max-width: 767px) {
        padding: 30px 25px;
        text-align: center;
     }
`;

const ModalTitle = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 32px;
    line-height: 1.2;
    color: #002a6e; // Темно-синий
    margin: 0 0 15px 0;
    font-weight: 700;

     @media (max-width: 767px) {
        font-size: 26px;
     }
`;

const ModalDescription = styled.p`
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin: 0 0 25px 0;

     @media (max-width: 767px) {
        font-size: 15px;
     }
`;

const HighlightTextExit = styled.span`
  color: #e67e22; // Другой оттенок оранжевого для акцента
  font-weight: 600;
`;

const FormExit = styled.form`
    display: flex;
    flex-direction: column;
    gap: 12px; // Меньший отступ
`;

const InputExit = styled.input`
    padding: 12px 15px; // Чуть меньше padding
    border: 1px solid #ccc;
    border-radius: 6px;
    font-family: 'Jost', sans-serif;
    font-size: 15px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    &:focus {
      outline: none;
      border-color: #004ee4;
      box-shadow: 0 0 0 2px rgba(0, 78, 228, 0.1);
    }
    &::placeholder { color: #aaa; }
     &:disabled { background-color: #e9ecef; cursor: not-allowed; }
`;

const SubmitButtonExit = styled.button`
    padding: 13px 20px;
    background: linear-gradient(90deg, #ff9900 0%, #ffbf4d 100%); // Градиент
    color: #fff;
    border: none;
    border-radius: 6px;
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: all 0.25s ease;
    box-shadow: 0 4px 10px rgba(255, 153, 0, 0.3);
    text-transform: uppercase;

    &:hover {
        background: linear-gradient(90deg, #e08900 0%, #f5a623 100%);
        transform: translateY(-1px);
        box-shadow: 0 6px 15px rgba(255, 153, 0, 0.4);
    }
    &:active {
        transform: translateY(0px) scale(0.98);
        box-shadow: 0 3px 8px rgba(255, 153, 0, 0.2);
    }
     &:disabled {
        background: #ccc;
        cursor: not-allowed;
        box-shadow: none;
        transform: none;
    }
`;

const ErrorMessageExit = styled.p`
    color: #d93025;
    font-size: 13px;
    margin: -5px 0 0 0;
    text-align: center;
`;

const SuccessMessageExit = styled.div`
    color: #0f5132;
    font-size: 16px;
    font-weight: 500;
    background-color: #d1e7dd;
    border: 1px solid #badbcc;
    border-radius: 8px;
    padding: 18px;
    text-align: center;
    line-height: 1.5;
`;

const PrivacyNoticeExit = styled.p`
    font-size: 11px;
    color: #999;
    text-align: center;
    line-height: 1.4;
    margin-top: 5px;
`;


export default ExitIntentModal;