import React, { useState } from 'react';
import styled from 'styled-components';
import { Plus, Minus } from 'react-feather'; // Using icons for toggle

const questionsAndAnswers = [
  // Keep your questions and answers
  { question: "Подходит ли ваш курс для новичков...", answer: "Наш курс разработан специально..." },
  { question: "Сколько длится курс?", answer: "Прохождение курса зависит от вашей скорости..." },
  { question: "Какой уровень владения английским языком необходим?", answer: "Достаточно базовых знаний..." },
  { question: "Какие нужны минимальные вложения?", answer: "Минимальные вложения зависят..." },
  { question: "Подходит ли курс для тех, кто не находится в США?", answer: "Да, вы можете открыть компанию..." },
  { question: "Есть ли готовый пакет услуг по запуску бизнеса?", answer: "Да, у нас есть готовые пакеты..." }
];

const FAQSection: React.FC = () => {
  // Initialize all as closed
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    // Allow only one to be open at a time
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <FAQSectionWrapper id="faq">
      <FAQContainer>
        <SectionHeader>
          <FAQTitle>ОСТАЛИСЬ ВОПРОСЫ?</FAQTitle>
          <FAQSubtitle>Здесь мы собрали ответы на самые частые из них.</FAQSubtitle>
        </SectionHeader>
        <FAQList>
          {questionsAndAnswers.map((item, index) => (
            // FIX: Pass $isOpen transient prop
            <FAQItem key={index} $isOpen={openIndex === index}>
              <FAQHeader onClick={() => toggleFAQ(index)}>
                <FAQQuestion>{item.question}</FAQQuestion>
                <ToggleIconWrapper>
                  {openIndex === index ? <Minus size={24} /> : <Plus size={24} />}
                </ToggleIconWrapper>
              </FAQHeader>
              {/* FIX: Pass $isOpen transient prop */}
              <AnswerWrapper $isOpen={openIndex === index}>
                <FAQAnswer>
                  <p>{item.answer}</p> {/* Wrap answer in p for better styling control */}
                </FAQAnswer>
              </AnswerWrapper>
            </FAQItem>
          ))}
        </FAQList>
      </FAQContainer>
    </FAQSectionWrapper>
  );
};

// --- Styled Components ---
const FAQSectionWrapper = styled.section`
  width: 100%;
  padding: 80px 20px; /* Consistent padding */
  background-color: #fff; /* White background */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
   @media (max-width: 991px) { padding: 60px 15px; }
`;

const FAQContainer = styled.div`
  display: flex; flex-direction: column; align-items: center; padding: 0;
  width: 100%; max-width: 900px; /* Slightly narrower for focus */ box-sizing: border-box;
`;

const SectionHeader = styled.div`
    text-align: center;
    margin-bottom: 50px;
     @media (max-width: 991px) { margin-bottom: 40px; }
`;

const FAQTitle = styled.h2`
  font-family: 'Bebas Neue', sans-serif; font-weight: 700;
  font-size: 56px; line-height: 1.1; color: #0E0B2C; /* Dark blue */
  margin-bottom: 10px;
  @media (max-width: 991px) { font-size: 44px; }
   @media (max-width: 767px) { font-size: 38px; }
`;

const FAQSubtitle = styled.p`
    font-size: 18px;
    color: #555;
    line-height: 1.6;
     @media (max-width: 991px) { font-size: 16px; }
`;


const FAQList = styled.div`
  display: flex; flex-direction: column; width: 100%;
  border: 1px solid #e2e8f0; /* Light border around list */
  border-radius: 12px; /* Rounded corners */
  overflow: hidden; /* Clip corners */
`;

// FIX: Use $isOpen transient prop
const FAQItem = styled.div<{ $isOpen: boolean }>`
  width: 100%; box-sizing: border-box;
  border-bottom: 1px solid #e2e8f0; /* Separator between items */
  &:last-child {
    border-bottom: none; /* No border on last item */
  }
  /* FIX: Use $isOpen transient prop */
  background-color: ${({ $isOpen }) => $isOpen ? '#f8faff' : '#fff'}; /* Highlight open item */
  transition: background-color 0.3s ease;
`;

const FAQHeader = styled.div`
  display: flex; justify-content: space-between; align-items: center;
  cursor: pointer; padding: 20px 25px; /* Padding inside header */
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f8f9fc; /* Subtle hover */
  }
  @media (max-width: 767px) { padding: 18px 20px; }
`;

const FAQQuestion = styled.h4`
  font-family: 'Jost', sans-serif; font-weight: 600; /* Bolder question */ font-size: 19px; /* Slightly smaller */
  line-height: 1.4; color: #1e293b; margin: 0; flex-grow: 1; padding-right: 15px; /* Space before icon */
  @media (max-width: 767px) { font-size: 17px; }
`;

const ToggleIconWrapper = styled.div`
  color: #004ee4; /* Icon color */ flex-shrink: 0;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55); // Same as burger
  ${FAQItem}:hover & {
      /* transform: scale(1.1); */ /* Optional: scale icon on hover */
  }
`;

// Wrapper for smooth height transition
// FIX: Use $isOpen transient prop
const AnswerWrapper = styled.div<{ $isOpen: boolean }>`
  /* FIX: Use $isOpen transient prop */
  max-height: ${({ $isOpen }) => ($isOpen ? '500px' : '0')}; /* Adjust max-height as needed */
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1); /* Smooth ease-in-out */
`;

const FAQAnswer = styled.div`
  padding: 0px 25px 25px 25px; /* Padding only when open */
  font-family: 'Jost', sans-serif; font-weight: 400;
  font-size: 16px; line-height: 1.7; color: #334155; /* Muted answer text */
  p { margin: 0; } /* Remove default margin from p tag */
   @media (max-width: 767px) { padding: 0px 20px 20px 20px; font-size: 15px;}
`;

export default FAQSection;