import React, { useState, useEffect } from 'react'; // Добавил useEffect для примера
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext'; // Assuming this context exists

// Mock useAuth if not available
const useAuth = () => ({ currentUser: null }); // Replace with your actual hook

const Header: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { currentUser } = useAuth();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    }

    // Add effect to close menu on clicking outside (optional but good UX)
    // useEffect(() => {
    //     const handleOutsideClick = (event: MouseEvent) => {
    //         const headerElement = document.getElementById('main-header');
    //         if (menuOpen && headerElement && !headerElement.contains(event.target as Node)) {
    //              closeMenu();
    //         }
    //     };

    //     if (menuOpen) {
    //         // Use timeout to prevent immediate closing due to the click that opened it
    //         const timerId = setTimeout(() => document.addEventListener('click', handleOutsideClick), 0);
    //         return () => {
    //             clearTimeout(timerId);
    //             document.removeEventListener('click', handleOutsideClick);
    //         };
    //     } else {
    //         document.removeEventListener('click', handleOutsideClick);
    //     }
    // }, [menuOpen]);


    return (
        <HeaderContainer id="main-header"> {/* Добавлен id */}
            <HeaderContent>
                <LogoContainer href="/" onClick={closeMenu}>
                    <LogoText>AMAZON FREEDOM</LogoText>
                    <LogoImage
                        loading="eager" // Load logo eagerly
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f27a0af8f1726ac0ac17ad101443f7676c6139e802a29fc0570403f9a8c55ff?apiKey=35bf81d825094f66b687d157fe963e7b&"
                        alt="Amazon Freedom Logo Mark"
                    />
                </LogoContainer>
                <NavLinks $menuOpen={menuOpen}>
                    <NavLink href="#about" onClick={closeMenu}>Обо мне</NavLink>
                    <NavLink href="#courses" onClick={closeMenu}>Курсы</NavLink>
                    <NavLink href="#forwho" onClick={closeMenu}>Кому подойдет</NavLink>
                    {/* --- ДОБАВЛЕНА ССЫЛКА --- */}
                    <NavLink href="#reviews" onClick={closeMenu}>Отзывы</NavLink>
                    <NavLink href="#faq" onClick={closeMenu}>FAQ</NavLink>
                    {currentUser && <NavLink as={Link} to="/dashboard" onClick={closeMenu}>Личный Кабинет</NavLink>}
                    <ApplyButtonContainerMobile href="#courses" onClick={closeMenu}>
                        <ApplyButtonText>Оставить заявку</ApplyButtonText>
                        <ApplyButtonIcon
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9497d9dd78f06ef12525fead37fd76fe82afbda7ad73f281a12d48f88aa3f98c?apiKey=35bf81d825094f66b687d157fe963e7b&"
                            alt=""
                        />
                    </ApplyButtonContainerMobile>
                </NavLinks>
                <ApplyButtonContainerDesktop href="#courses">
                    <ApplyButtonText>Оставить заявку</ApplyButtonText>
                    <ApplyButtonIcon
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9497d9dd78f06ef12525fead37fd76fe82afbda7ad73f281a12d48f88aa3f98c?apiKey=35bf81d825094f66b687d157fe963e7b&"
                        alt="" // Decorative icon
                    />
                </ApplyButtonContainerDesktop>
                <BurgerMenu onClick={toggleMenu} aria-label="Toggle menu" aria-expanded={menuOpen}>
                    <BurgerBar $open={menuOpen} />
                    <BurgerBar $open={menuOpen} />
                    <BurgerBar $open={menuOpen} />
                </BurgerMenu>
            </HeaderContent>
        </HeaderContainer>
    );
};

// --- Styled Components (без изменений, код ниже) ---
const HeaderContainer = styled.header`
    background-color: #001B4F;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px 0;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 15, 40, 0.15);
    transition: background-color 0.3s ease;
`;

const HeaderContent = styled.div`
    display: flex;
    width: 100%;
    max-width: 1240px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #fff;
    gap: 20px;

    @media (max-width: 991px) {
        padding: 0 15px;
    }
`;

const LogoContainer = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    flex-shrink: 0;
     transition: transform 0.2s ease;
     &:hover {
        transform: scale(1.02);
     }
`;

const LogoText = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    color: white;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
`;

const LogoImage = styled.img`
    width: 30px;
    height: auto;
    margin-top: 5px;
`;

const NavLinks = styled.nav<{ $menuOpen: boolean }>`
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 15px;
    margin-left: auto;

    @media (max-width: 991px) {
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #001644;
        transition: transform 0.35s cubic-bezier(0.25, 0.1, 0.25, 1),
                    opacity 0.3s ease-in-out,
                    visibility 0.35s ease;
        transform: ${({ $menuOpen }) => ($menuOpen ? 'translateY(0)' : 'translateY(-20px)')};
        opacity: ${({ $menuOpen }) => ($menuOpen ? '1' : '0')};
        visibility: ${({ $menuOpen }) => ($menuOpen ? 'visible' : 'hidden')};
        pointer-events: ${({ $menuOpen }) => ($menuOpen ? 'auto' : 'none')};
        padding: 20px 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

const NavLink = styled.a`
    font-family: 'Jost', sans-serif;
    color: white;
    padding: 8px 5px;
    text-decoration: none;
    position: relative;
    transition: color 0.25s ease;
    font-weight: 400;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f90;
        transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover, &:focus {
        color: #f90;
        outline: none;
    }

    &:hover::after {
        width: 100%;
    }

    @media (max-width: 991px) {
        padding: 15px 20px;
        width: 90%;
        text-align: center;
        font-size: 16px;
        border-radius: 5px;
        &::after { display: none; }
        &:hover, &:focus {
            background-color: rgba(255, 255, 255, 0.08);
            color: #f90;
        }
    }
`;

const ApplyButtonBase = css`
    border-radius: 140px;
    background-color: #f90;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 12px 25px;
    text-decoration: none;
    transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
    white-space: nowrap;
    box-shadow: 0 3px 8px rgba(255, 153, 0, 0.2);

    &:hover {
        background-color: #e08900;
        transform: scale(1.04);
        box-shadow: 0 5px 12px rgba(255, 153, 0, 0.3);
    }
     &:active {
        transform: scale(0.98);
     }
`;

const ApplyButtonContainerDesktop = styled.a`
    ${ApplyButtonBase}
    @media (max-width: 991px) {
        display: none;
    }
`;

const ApplyButtonContainerMobile = styled.a`
     ${ApplyButtonBase}
     display: none;
     margin-top: 15px;
     padding: 10px 25px;

     @media (max-width: 991px) {
        display: inline-flex;
     }
`;


const ApplyButtonText = styled.span`
    font-family: 'Jost', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
`;

const ApplyButtonIcon = styled.img`
    width: 18px;
    height: auto;
`;


const BurgerMenu = styled.button`
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 11;

    &:focus { outline: none; }

    @media (max-width: 991px) { display: flex; }
`;

const BurgerBar = styled.div<{ $open: boolean }>`
    width: 25px;
    height: 3px;
    background-color: white;
    border-radius: 10px;
    transition: all 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ $open }) => ($open ? 'rotate(45deg) translate(3px, -1px)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ $open }) => ($open ? '0' : '1')};
      transform: ${({ $open }) => ($open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
       transform: ${({ $open }) => ($open ? 'rotate(-45deg) translate(3px, 1px)' : 'rotate(0)')};
    }
`;


export default Header;